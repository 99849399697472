import { Box } from '@mui/material';

interface AlphaModalContentProps {
  children: React.ReactNode;
}

export function AlphaModalContent({ children }: AlphaModalContentProps) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 2,
        overflowY: 'auto',
        bgcolor: 'background.paper',
      }}
    >
      {children}
    </Box>
  );
}
