import { request } from '@core/data/request';

export interface GroupParams {
  id?: any;
  name?: string;
}

export interface GroupListParams {
  data: GroupParams[];
}

export interface FunctionalityParams {
  allFunctionalities: AllRoutesParams[];
}

export interface AllRoutesParams {
  id: number;
  name: string;
  functionalities: AllFunctionalitiesParams[];
  visible?: boolean;
}

export interface AllFunctionalitiesParams {
  id: number;
  name: string;
  icon: string;
  visible: boolean;
  order: number;
  children?: AllFunctionalitiesChildrenParams[];
}

export interface AllFunctionalitiesChildrenParams {
  id: number;
  name: string;
  icon: string;
  order: number;
  visible: boolean;
}

export interface AccessRoutesParams {
  id: number;
  functionalities: [];
}

export interface FunctionalityItemProps {
  id: number;
  order: number;
}

export interface SaveGroupFunctionalityProps {
  id: number | string;
  groupData: SaveGroupFunctionalityItemProps;
}

export interface SaveGroupFunctionalityItemProps {
  id: number | string;
  modules: { [key: number]: number };
  functionalities: FunctionalityItemProps[];
}

export interface SaveGroupComponentProps {
  id: number | string;
  functionalityId: number | string;
  groupData: SaveGroupComponentItemProps;
}

export interface SaveGroupComponentItemProps {
  components: any;
  columns: {
    id: number;
    name: string;
    order: number;
    visible: boolean | number;
  }[];
}

export const fetchAllGroups = async (): Promise<GroupListParams> => {
  return request<GroupListParams>({
    method: 'get',
    url: '/group/list-all-groups',
  });
};

export const registerGroup = async (groupData: GroupParams) => {
  return request<GroupParams>({
    method: 'post',
    url: '/group/register-group',
    data: {
      ...groupData,
    },
  });
};

export const fetchGroup = async (id: number | string): Promise<GroupParams> => {
  return request<GroupParams>({
    method: 'get',
    url: `/group/edit-group/${id}`,
  });
};

export const editGroup = async (
  id: number | string,
  groupData: GroupParams,
) => {
  return request<GroupParams>({
    method: 'put',
    url: `/group/edit-group/${id}`,
    data: {
      ...groupData,
    },
  });
};

export const listFunctionalities = async (
  id: number | string,
): Promise<AllRoutesParams[]> => {
  return request<AllRoutesParams[]>({
    method: 'get',
    url: `/group/manage-functionality/${id}`,
  });
};

export const listComponents = async (
  id: number | string,
  functionalityId: number | string,
): Promise<SaveGroupComponentItemProps> => {
  return request<SaveGroupComponentItemProps>({
    method: 'get',
    url: `/group/manage-component/${id}/${functionalityId}`,
  });
};

export const savePermissionFunctionalityGroup = async (
  id: number | string,
  groupData: SaveGroupFunctionalityProps,
) => {
  return request({
    method: 'put',
    url: `/group/manage-functionality/${id}`,
    data: groupData,
  });
};

export const savePermissionComponentsGroup = async (
  id: number | string,
  functionalityId: number | string,
  groupData: SaveGroupComponentItemProps,
) => {
  return request({
    method: 'put',
    url: `/group/manage-component/${id}/${functionalityId}`,
    data: groupData,
  });
};
