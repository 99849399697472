import { Suspense, ReactNode } from 'react';
import { AlphaLoading } from '.';

interface AlphaSuspenseProps {
  children: ReactNode;
}

const AlphaSuspense = ({ children }: AlphaSuspenseProps) => {
  return <Suspense fallback={<AlphaLoading />}>{children}</Suspense>;
};

export default AlphaSuspense;
