import { request, requestTable } from '@core/data/request';

export const fetchTable = async (
  tableEndpoint: string,
  params: any,
): Promise<any> => {
  return requestTable({
    endpoint: `/table/list-all/${tableEndpoint}`,
    ...params,
  });
};

export const fetchTableStructure = async (
  tableEndpoint: string,
): Promise<any> => {
  return request<any>({
    method: 'get',
    url: `/table/structure/${tableEndpoint}`,
  });
};
