import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAlphaModal } from '../Modal/AlphaModal';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { fetchTable } from '@core/services/tableService';
import removeAccents from 'remove-accents';

interface ExportProps {
  open: boolean;
  onClose: () => void;
  tableEndpoint: string;
  fileName?: string;
  tableHeaders?: any;
}

interface StructureProps {
  id: number;
  name: string;
  label: string;
  order: number;
}

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const normalizeString = (str: string | null | undefined) => {
  const safeStr = str ?? '';
  return removeAccents(safeStr.toLowerCase().replace(/[.]/g, ''));
};

const columnsToRemove = [
  'ações',
  'created_at',
  'created_by',
  'updated_by',
  'updated_at',
];

const ExportModal = ({
  open,
  onClose,
  tableEndpoint,
  fileName,
  tableHeaders,
}: ExportProps) => {
  const AlphaModal = useAlphaModal();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [format, setFormat] = useState<'csv' | 'pdf'>('csv');
  const [structure, setStructure] = useState<StructureProps[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [nameExport, setNameExport] = useState(fileName ?? 'table');

  const { refetch } = useQuery({
    queryKey: ['@table', tableEndpoint],
    queryFn: async () => {
      const data = await fetchTable(tableEndpoint, {
        params: {
          offset: 0,
          size: 0,
          filters: [],
          globalFilter: [],
          sorting: [],
        },
      });
      const json = data.response;
      return json;
    },
    enabled: false,
  });

  useEffect(() => {
    const structureData = queryClient.getQueryData<StructureProps[]>([
      '@structure_',
      tableEndpoint,
    ]);
    setStructure(structureData ?? []);
    setVisibleColumns(structureData?.map((data) => data.name) ?? []);
  }, [queryClient, tableEndpoint]);

  const handleExport = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const result = await refetch();

      if (!result.data?.data) {
        throw new Error('Table data is not available');
      }

      if (format === 'csv') {
        handleExportCsv(result.data.data, structure);
      } else if (format === 'pdf') {
        handleExportPdf(result.data.data, structure);
      }
    } catch (error) {
      console.error('Erro ao exportar:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportCsv = useCallback(
    (rows: any[], columns: any[]) => {
      const columnsCleaned = columnsToRemove.map(normalizeString);

      const normalizedVisibleColumns = visibleColumns.map(normalizeString);

      const filteredColumns = columns.filter((column) => {
        const normalizedLabel = normalizeString(column.label);
        const normalizedName = normalizeString(column.name);
        const isInVisibleColumns =
          normalizedVisibleColumns.includes(normalizeString(column.label)) ||
          normalizedVisibleColumns.includes(normalizeString(column.name));

        return (
          isInVisibleColumns &&
          !columnsCleaned.includes(normalizedLabel) &&
          !columnsCleaned.includes(normalizedName)
        );
      });
      const data = rows.map((row) => {
        const newRow: { [key: string]: any } = {};
        filteredColumns.forEach((column) => {
          newRow[column.name] = row[column.label];
        });
        return newRow;
      });

      csvConfig.filename = normalizeString(nameExport);

      const csv = generateCsv(csvConfig)(data);

      download(csvConfig)(csv);
    },
    [structure, nameExport, visibleColumns],
  );

  const handleExportPdf = useCallback(
    (rows: any[], columns: any[]) => {
      const doc = new jsPDF();

      const normalizedVisibleColumns = visibleColumns.map(normalizeString);
      const filteredColumns = columns.filter((column) => {
        const normalizedLabel = normalizeString(column.label);
        const normalizedName = normalizeString(column.name);
        return (
          normalizedVisibleColumns.includes(normalizedLabel) ||
          normalizedVisibleColumns.includes(normalizedName)
        );
      });

      const tableData = rows.map((row) =>
        filteredColumns.map((column) => row[column.label] || ''),
      );

      const filteredHeaders = tableHeaders
        .filter((header: any) =>
          filteredColumns.some(
            (column) =>
              normalizeString(column.name) === normalizeString(header.header) ||
              normalizeString(column.name) === normalizeString(header.id),
          ),
        )
        .map((header: any) => header.header);

      autoTable(doc, {
        head: [filteredHeaders],
        body: tableData as RowInput[],
      });

      doc.save(`${nameExport}.pdf`);
    },
    [nameExport, visibleColumns],
  );

  return (
    <>
      <AlphaModal.Root
        open={open}
        onClose={onClose}
        title="Opções de Exportação"
        styleProps={{ width: '70%', height: '95%' }}
      >
        <AlphaModal.Content>
          <Box p={2}>
            <Grid container spacing={2} size={{ xs: 12, sm: 12 }}>
              <Grid size={{ xs: 12, sm: 12 }}>
                <Box>
                  <Typography variant="h5" gutterBottom fontWeight={'bold'}>
                    Título do Arquivo
                  </Typography>
                  <TextField
                    sx={{ marginTop: '10px' }}
                    fullWidth
                    value={nameExport}
                    onChange={(e) => setNameExport(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid mt={2} size={{ xs: 12, sm: 12 }}>
                <Box>
                  <Typography variant="h5" gutterBottom fontWeight={'bold'}>
                    Formato do Documento
                  </Typography>
                  <Box
                    display="inline-flex"
                    mt={1}
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      border: '1px solid',
                      borderColor: theme.palette.divider,
                      borderRadius: '8px',
                      padding: '4px',
                      transition: 'all 0.3s',
                    }}
                  >
                    <Box
                      component="label"
                      mr={1}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                        border: format === 'csv' ? '1px solid #000' : '',
                        borderColor: 'primary.main',
                        backgroundColor: format === 'csv' ? 'primary.main' : '',
                        opacity: format === 'csv' ? 1 : 0.5,
                        borderRadius: '8px',
                        padding: '14px',
                        transition: 'all 0.3s',
                      }}
                      onClick={() => setFormat('csv')}
                    >
                      <Typography
                        variant="h6"
                        component="span"
                        color={format === 'csv' ? 'white' : 'gray'}
                      >
                        CSV
                      </Typography>
                    </Box>
                    <Box
                      component="label"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        cursor: 'pointer',
                        border: format === 'pdf' ? '1px solid #000' : '',
                        borderColor: 'primary.main',
                        opacity: format === 'pdf' ? 1 : 0.5,
                        backgroundColor: format === 'pdf' ? 'primary.main' : '',
                        borderRadius: '8px',
                        padding: '14px',
                        transition: 'all 0.3s',
                      }}
                      onClick={() => setFormat('pdf')}
                    >
                      <Typography
                        variant="h6"
                        component="span"
                        color={format === 'pdf' ? 'white' : 'gray'}
                      >
                        PDF
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid mt={2} size={{ xs: 12, sm: 12 }}>
                <Box>
                  <Typography variant="h5" gutterBottom fontWeight={'bold'}>
                    Colunas para Exportação
                  </Typography>
                  <Box
                    mt={1}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns:
                        'repeat(auto-fill, minmax(200px, 1fr))',
                      gap: '16px',
                      maxHeight: '400px',
                      overflowY: 'auto',
                      padding: '16px',
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    {structure &&
                      structure
                        .filter((column) => {
                          const normalizedLabel = normalizeString(
                            column.label ?? '',
                          );
                          const normalizedName = normalizeString(
                            column.name ?? '',
                          );

                          return !columnsToRemove.some(
                            (removeColumn) =>
                              normalizedLabel ===
                                normalizeString(removeColumn) ||
                              normalizedName === normalizeString(removeColumn),
                          );
                        })
                        .map((column, index) => (
                          <Card
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '12px',
                              backgroundColor:
                                theme.palette.mode === 'dark'
                                  ? theme.palette.grey[900]
                                  : theme.palette.grey[200],
                              borderRadius: '8px',
                              transition: 'all 0.3s',
                              '&:hover': {
                                backgroundColor:
                                  theme.palette.mode === 'dark'
                                    ? theme.palette.grey[800]
                                    : theme.palette.grey[300],
                              },
                            }}
                          >
                            <CardContent
                              sx={{ flex: 1, padding: '0 !important' }}
                            >
                              <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                              >
                                {column.name}
                              </Typography>
                            </CardContent>
                            <Box>
                              <Checkbox
                                checked={visibleColumns.includes(column.name)}
                                onChange={() => {
                                  setVisibleColumns((prev) => {
                                    if (prev.includes(column.name)) {
                                      return prev.filter(
                                        (item) => item !== column.name,
                                      );
                                    }
                                    return [...prev, column.name];
                                  });
                                }}
                              />
                            </Box>
                          </Card>
                        ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </AlphaModal.Content>
        <AlphaModal.Footer>
          <AlphaModal.Action onClick={onClose} color="error" variant="text">
            Fechar
          </AlphaModal.Action>
          <AlphaModal.Action
            color="primary"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleExport}
          >
            Exportar
          </AlphaModal.Action>
        </AlphaModal.Footer>
      </AlphaModal.Root>

      <Dialog
        open={loading}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '16px',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            mb: 2,
          }}
        >
          Exportando
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <CircularProgress size={60} thickness={4} />
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', maxWidth: '80%' }}
          >
            Aguarde enquanto o arquivo está sendo gerado...
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExportModal;
