import { api } from '@core/data/api';
import { request } from '@core/data/request';

export const loginAuth = async (
  email: string,
  password: string,
  userLoginToken: string,
) => {
  return await api.post('auth/login', {
    email,
    password: password,
    userLoginToken,
  });
};

export const activeAccountAuth = async (password: string) => {
  return request({
    method: 'patch',
    url: '/auth/activate-account',
    data: {
      password,
    },
  });
};

export const forgotPasswordAuth = async (email: string) => {
  return request({
    method: 'post',
    url: '/auth/forgot-password',
    data: {
      email,
    },
  });
};

export const verifyActivateToken = async (activateAccountToken: string) => {
  return await api.post('auth/verify-activate-account-token', {
    activateAccountToken,
  });
};

export const logoutAuth = async () => {
  return request({
    method: 'post',
    url: '/auth/logout',
  });
};
