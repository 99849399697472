import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface FavoritesRoutes {
  name: string;
  path: string;
  icon: string;
}

interface UserPreferencesState {
  favoritesRoutes: FavoritesRoutes[];
  setFavoritesRoutes: (favoritesRoutes: FavoritesRoutes[]) => void;
}

const useUserPreferencesStore = create<UserPreferencesState>()(
  devtools(
    persist(
      (set) => ({
        favoritesRoutes: [],
        setFavoritesRoutes: (favoritesRoutes) =>
          set({ favoritesRoutes }, false, 'setFavoritesRoutes'),
      }),
      {
        name: 'userPreferences', // localStorage
      },
    ),
    {
      store: 'userPreferences', //devtools
      name: 'userPreferences',
      enabled: process.env.NODE_ENV == 'development', //devtools disable production
    },
  ),
);

export default useUserPreferencesStore;
