import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useTheme,
  alpha,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { AllRoutesParams, listFunctionalities } from '../services/groupService';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FunctionalityAccordion from './GroupFunctionalityAccordion';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  minHeight: '4rem',
  width: '100%',
  margin: '10px 0',
  boxShadow: '0 4px 20px 0 ' + alpha(theme.palette.common.black, 0.1),
  transition: 'all 0.3s ease-in-out',
  border: '1px solid ' + alpha(theme.palette.common.black, 0.08),
  '& .MuiAccordionSummary-root': {
    minHeight: '3rem',
    padding: theme.spacing(0, 2),
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(2),
  },
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },
  '&:hover': {
    boxShadow: '0 6px 24px 0 ' + alpha(theme.palette.common.black, 0.15),
  },
  '&.Mui-expanded': {
    margin: '16px 0',
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transition: 'transform 0.3s',
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(2),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  transition: 'color 0.3s ease',
  maxWidth: 300,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

interface GroupModuleAccordionProps {
  id: number;
  child: any;
  setChild: any;
  functionalities: any;
  setFunctionalities: any;
  setModules: any;
  setChildEdit: any;
}

const GroupModuleAccordion = ({
  id,
  child,
  setChild,
  functionalities,
  setFunctionalities,
  setModules,
  setChildEdit,
}: GroupModuleAccordionProps) => {
  const theme = useTheme();
  const [expandedCard, setExpandedCard] = useState<number | false>(false);
  const [visibility, setVisibility] = useState<{ [key: number]: boolean }>({});

  const { data: allRoutes } = useQuery<AllRoutesParams[]>({
    queryKey: ['list-functionalities', id],
    queryFn: () => listFunctionalities(Number(id)),
  });

  const [routes, setRoutes] = useState(allRoutes || []);

  useEffect(() => {
    if (allRoutes) {
      const initialVisibility = initializeVisibility(allRoutes || []);
      setRoutes(allRoutes || []);
      const initialModules = allRoutes?.reduce((acc: any, route: any) => {
        acc[route.id] = initialVisibility[route.id];
        return acc;
      }, {});

      setModules(initialModules);
      setVisibility(initialVisibility);
    }
  }, [allRoutes]);

  const initializeVisibility = (routes: any[]) => {
    const initialVisibility: { [key: number]: boolean } = {};
    routes.forEach((route) => {
      initialVisibility[route.id] = route.visible;
    });
    return initialVisibility;
  };

  const handleExpandClick = (id: number) => {
    setExpandedCard(expandedCard === id ? false : id);
  };

  const handleCheckboxChange = (id: number) => {
    setVisibility((prevVisibility: any) => {
      if (prevVisibility[id]) {
        expandedCard === id && setExpandedCard(false);
      }

      const newVisibility = {
        ...prevVisibility,
        [id]: !prevVisibility[id],
      };

      return newVisibility;
    });
    setModules((prevModules: any) => ({
      ...prevModules,
      [id]: !prevModules[id],
    }));
  };

  return (
    <Box width="100%">
      {routes.map((route, index) => (
        <StyledAccordion
          key={route.name}
          expanded={expandedCard === route.id}
          sx={{
            border: `1px solid ${
              expandedCard === route.id
                ? theme.palette.primary.main
                : theme.palette.background.paper
            }`,
          }}
        >
          <StyledAccordionSummary>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
            >
              <Checkbox
                icon={<VisibilityOffOutlinedIcon />}
                checkedIcon={<VisibilityOutlinedIcon />}
                checked={visibility[route.id] || false}
                onChange={() => handleCheckboxChange(route.id)}
                sx={{
                  marginLeft: '-5px',
                }}
              />
              <Tooltip title={route.name} arrow>
                <CardTitle>{route.name}</CardTitle>
              </Tooltip>
            </Box>
            <IconButton
              sx={{
                marginLeft: 'auto',
                color: theme.palette.text.primary,
              }}
              onClick={() => {
                handleExpandClick(route.id);
              }}
            >
              <ExpandMoreOutlinedIcon
                sx={{
                  transform:
                    expandedCard === route.id
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
            </IconButton>
          </StyledAccordionSummary>
          <AccordionDetails>
            <FunctionalityAccordion
              func={route.functionalities as any}
              child={child}
              setChild={setChild}
              setChildEdit={setChildEdit}
              functionalities={functionalities}
              setFunctionalities={setFunctionalities}
            />
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default GroupModuleAccordion;
