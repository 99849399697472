import { User } from '@ilogix/hooks/useAuth';
import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

export interface mostAccessedFunctionalities {
  id: number;
  name?: string;
  functionality_id: number;
  path: string;
  created_at: string;
  updated_at: string | null;
}

interface InitProps {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
  mostAccessedFunctionalities: mostAccessedFunctionalities[] | [];
}

interface AuthState {
  user: User | null;
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken?: string | null;
  mostAccessedFunctionalities: mostAccessedFunctionalities[];
  init: (payload: InitProps) => void;
  login: (user: User, accessToken: string) => void;
  logout: () => void;
  register: (user: User, accessToken: string) => void;
}

const environment = import.meta.env.VITE_APP_ENV;

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        isInitialised: false,
        isAuthenticated: false,
        accessToken: null,
        mostAccessedFunctionalities: [],
        init: ({
          isAuthenticated,
          user,
          accessToken,
          mostAccessedFunctionalities,
        }) =>
          set(
            {
              isAuthenticated,
              isInitialised: true,
              user,
              accessToken,
              mostAccessedFunctionalities,
            },
            false,
            'init',
          ),
        login: (user, accessToken) =>
          set(
            {
              isAuthenticated: true,
              user,
              accessToken,
            },
            false,
            'login',
          ),
        logout: () =>
          set(
            {
              isAuthenticated: false,
              user: null,
              accessToken: null,
              mostAccessedFunctionalities: [],
            },
            false,
            'logout',
          ),
        register: (user, accessToken) =>
          set(
            {
              isAuthenticated: true,
              user,
              accessToken,
            },
            false,
            'register',
          ),
      }),
      {
        name: 'auth', // localStorage
      },
    ),
    {
      store: 'auth', //devtools
      name: 'auth',
      enabled: environment == 'development', //devtools disable production
    },
  ),
);

export default useAuthStore;
