import { request } from '@core/data/request';

export interface UserChangePasswordParams {
  password?: string;
  newPassword?: string;
}

export const changePasswordUser = async (
  userData: UserChangePasswordParams,
) => {
  /* const form = new FormData();
  form.append('password', userData.password ?? '');
  form.append('newPassword', userData.newPassword ?? '');

  return request<any>({
    method: 'patch',
    url: '/user/update-password',
    data: form,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  }); */

  return request<UserChangePasswordParams>({
    method: 'patch',
    url: '/user/update-password',
    data: {
      ...userData,
    },
  });
};
