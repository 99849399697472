import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { notify } from '@core/utils/notifyUtils';
import { TableRow } from '@core/components/DataTable/CreateList';
import { LogListParams } from '../services/logService';
import useTableStore from '@core/store/tableStore';

export const CustomCellJsonView = ({
  row,
  handleOpen,
  setRowData,
  rowType,
}: {
  row: TableRow<LogListParams>;
  handleOpen: any;
  setRowData: any;
  rowType: 'Body' | 'Param';
}) => {
  const { density } = useTableStore();
  const handleDownload = () => {
    const blob = new Blob(
      [
        JSON.stringify(
          rowType === 'Body' ? row.original.body : row.original.param,
          null,
          2,
        ),
      ],
      {
        type: 'application/json',
      },
    );
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'log.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(rowType === 'Body' ? row.original.body : row.original.param)
      .then(() => {
        notify('info', 'Conteúdo copiado para a área de transferência!');
      })
      .catch((err) => {
        console.error('Erro ao copiar: ', err);
      });
  };

  return (
    <Box>
      <Tooltip title="Visualizar" arrow>
        <IconButton
          onClick={() => {
            handleOpen();
            setRowData(
              rowType === 'Body' ? row.original.body : row.original.param,
            );
          }}
          color="default"
          size={density === 'compact' ? 'small' : 'medium'}
          sx={{
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.light,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              color: 'white',
              cursor: 'pointer',
            },
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      {rowType === 'Body' && (
        <Tooltip title="Download do Conteúdo" arrow>
          <IconButton
            onClick={() => handleDownload()}
            color="default"
            size={density === 'compact' ? 'small' : 'medium'}
            sx={{
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.light,
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                color: 'white',
                cursor: 'pointer',
              },
            }}
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Copiar Conteúdo" arrow>
        <IconButton
          onClick={() => handleCopy()}
          color="default"
          size={density === 'compact' ? 'small' : 'medium'}
          sx={{
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.light,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              color: 'white',
              cursor: 'pointer',
            },
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
