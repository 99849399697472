import { AlphaBox } from '@core/components/Layout';
import useAuthStore from '@core/store/authStore';
import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const ProfileGeneral = () => {
  const user = useAuthStore((state) => state.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AlphaBox>
      <Typography variant={isMobile ? 'h5' : 'h4'} mb={1}>
        Geral
      </Typography>
      <Typography color="gray" sx={{ mb: 5 }}>
        Defina as configurações gerais do perfil
      </Typography>
      <Divider />
      <Stack
        sx={{ mt: 5, mb: 5 }}
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
      >
        <Box>
          <Typography variant="h5">Nome</Typography>
          <Typography color="gray" fontSize={13}>
            Nome do Perfil
          </Typography>
        </Box>
        <Box
          sx={{
            pl: isMobile ? 0 : 20,
            width: isMobile ? '100%' : '60%',
          }}
        >
          <Typography variant="h5" fontWeight={200}>
            {user?.name}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <Stack sx={{ mt: 5 }} direction={isMobile ? 'column' : 'row'} spacing={2}>
        <Box>
          <Typography variant="h5">Email</Typography>
          <Typography color="gray" fontSize={13}>
            Email do Perfil
          </Typography>
        </Box>
        <Box
          sx={{
            pl: isMobile ? 0 : 20,
            width: isMobile ? '100%' : '60%',
          }}
        >
          <Typography variant="h5" fontWeight={200}>
            {user?.email}
          </Typography>
        </Box>
      </Stack>
    </AlphaBox>
  );
};

export default ProfileGeneral;
