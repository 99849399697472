import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface DatePickerFilterProps {
  handleCustomFilterChange: (header: any, value: any) => void;
  getCustomFilterValue: () => any;
  theme: any;
  header: any;
  isCustom: boolean;
  headerFilter: boolean;
  handleFilterChange: (value: any, isCustom: boolean) => void;
}

const DatePickerFilter: React.FC<DatePickerFilterProps> = React.memo(
  ({
    handleCustomFilterChange,
    getCustomFilterValue,
    theme,
    header,
    isCustom,
    headerFilter,
    handleFilterChange,
  }) => {
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);
    const textFieldSx = useMemo(
      () => ({
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#d3d3d3' : '#737373',
        },
        ...(headerFilter
          ? {
              width: '100%',
              maxWidth: '100%',
              ...header.column.columnDef.filterStyle,
            }
          : {}),
      }),
      [theme.palette.mode, headerFilter, header.column.columnDef.filterStyle],
    );

    const handleDatePickerChange = useCallback(
      (newValue: any, type: 'from' | 'to') => {
        const formattedValue =
          newValue && dayjs(newValue).isValid()
            ? dayjs(newValue).format('YYYY-MM-DD')
            : null;

        if (type === 'from') {
          setFromDate(formattedValue);
        } else if (type === 'to') {
          setToDate(formattedValue);
        }
      },
      [],
    );

    const getDatePickerValue = useCallback(
      (type: 'from' | 'to') => {
        const value = header.column.getFilterValue()?.[type];
        return value && dayjs(value, 'YYYY-MM-DD').isValid()
          ? dayjs(value)
          : null;
      },
      [header.column],
    );

    const handleChange = useCallback(
      (newValue: any, type: 'from' | 'to') => {
        const formattedValue =
          newValue && dayjs(newValue, 'DD/MM/YYYY').isValid()
            ? dayjs(newValue).format('YYYY-MM-DD')
            : null;

        if (isCustom) {
          handleCustomFilterChange(header, formattedValue);
        } else {
          handleDatePickerChange(newValue, type);
        }
      },
      [isCustom, handleCustomFilterChange, header, handleDatePickerChange],
    );

    useEffect(() => {
      const newFilterValue = {
        from: fromDate || null,
        to: toDate || null,
      };

      const shouldSetFilter = newFilterValue.from || newFilterValue.to;

      header.column.setFilterValue(
        shouldSetFilter ? newFilterValue : undefined,
      );
      handleFilterChange(newFilterValue, isCustom);
    }, [fromDate, toDate, isCustom, handleFilterChange]);

    const datePickerCommonProps = useMemo(
      () => ({
        format: 'DD/MM/YYYY',
        slotProps: {
          textField: {
            sx: textFieldSx,
          },
        },
      }),
      [textFieldSx],
    );

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={1}>
          <DatePicker
            {...datePickerCommonProps}
            label="Data Início"
            value={
              isCustom
                ? dayjs(getCustomFilterValue())
                : getDatePickerValue('from')
            }
            onChange={(newValue) => handleChange(newValue, 'from')}
          />
          <DatePicker
            {...datePickerCommonProps}
            label="Data Final"
            value={
              isCustom
                ? dayjs(getCustomFilterValue())
                : getDatePickerValue('to')
            }
            onChange={(newValue) => handleChange(newValue, 'to')}
          />
        </Stack>
      </LocalizationProvider>
    );
  },
);

DatePickerFilter.displayName = 'DatePickerFilter';

export default DatePickerFilter;
