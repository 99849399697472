import { Box, Card, Typography, useTheme } from '@mui/material';

interface Props {
  value: number;
  title: string;
  color: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';
  Icon: any;
}

const IntegrationCard = ({ value, title, color, Icon }: Props) => {
  const theme = useTheme();

  const colorMapping: Record<string, string> = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
  };

  const getTextColor = () => colorMapping[color] || undefined;

  return (
    <Card
      sx={{
        padding: 2,
        borderRadius: 4,
        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 12px 30px 0 rgba(0,0,0,0.18)',
        },
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: -20,
          top: '50%',
          transform: 'translateY(-50%)',
          opacity: 0.2,
        }}
      >
        <Icon sx={{ fontSize: 100, color: getTextColor() }} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
        textAlign={'right'}
      >
        <Typography variant="h4" fontWeight={'bold'} color={color} mb={1}>
          {value}
        </Typography>
        <Typography fontSize={15} color="textSecondary">
          {title}
        </Typography>
      </Box>
    </Card>
  );
};

export default IntegrationCard;
