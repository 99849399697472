import { AlphaModalAction } from './AlphaModalAction';
import { AlphaModalContent } from './AlphaModalContent';
import { AlphaModalFooter } from './AlphaModalFooter';
import { AlphaModalRoot } from './AlphaModalRoot';

export function useAlphaModal() {
  return {
    Root: AlphaModalRoot,
    Content: AlphaModalContent,
    Action: AlphaModalAction,
    Footer: AlphaModalFooter,
  };
}
