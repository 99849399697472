import { request } from '@core/data/request';

export interface UserParams {
  id?: number | string;
  name?: string;
  email?: string;
  group_id?: string;
  group_name?: string;
  active?: boolean;
}

export interface UserLastLoginParams {
  created_at: string;
  user_ip: string;
  browser: string;
  operation_system: string;
  user_agent: string;
}
export interface UserListParams {
  id: number;
  name: string;
  active: number;
  group_name: string;
}

export const registerUser = async (userData: UserParams) => {
  return request<UserParams>({
    method: 'post',
    url: '/user/register-user',
    data: {
      ...userData,
    },
  });
};

export const fetchUser = async (id: number | string): Promise<UserParams> => {
  return request<UserParams>({
    method: 'get',
    url: `/user/edit-user/${id}`,
  });
};

export const editUser = async (id: number | string, userData: UserParams) => {
  return request<UserParams>({
    method: 'put',
    url: `/user/edit-user/${id}`,
    data: {
      ...userData,
    },
  });
};

export const toggleUserStatus = async (
  id: number | string,
  active: boolean,
) => {
  return request<UserParams>({
    method: 'patch',
    url: `/user/activate-user/${id}`,
    data: {
      active,
    },
  });
};

export const fetchUserLastLogin = async (
  id: number | string,
): Promise<UserLastLoginParams[]> => {
  return request<UserLastLoginParams[]>({
    method: 'get',
    url: `/user/get-log-access/${id}`,
  });
};
