import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as Icons from '@mui/icons-material';
import { LibraryAdd } from '@mui/icons-material';

interface IconSelectorProps {
  onSelectIcon: (iconName: string) => void;
}

const IconSelector: React.FC<IconSelectorProps> = ({ onSelectIcon }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const reactComponentToIconName = (componentName: string): string => {
    return componentName
      .replace(/([A-Z])/g, '_$1')
      .toLowerCase()
      .replace(/^_/, '')
      .replace(/_outlined$/, '');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm('');
  };

  const handleIconSelect = useCallback(
    (iconName: string) => {
      onSelectIcon(iconName);
      handleClose();
    },
    [onSelectIcon],
  );

  const filteredIcons = useMemo(() => {
    return Object.entries(Icons).filter(([name]) =>
      name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm]);

  const open = Boolean(anchorEl);
  const id = open ? 'icon-popover' : undefined;

  const Cell = ({ columnIndex, rowIndex, style }: any) => {
    const index = rowIndex * 4 + columnIndex;
    if (index >= filteredIcons.length) return null;

    const [componentName, Icon] = filteredIcons[index];
    const iconName = reactComponentToIconName(componentName);

    return (
      <Box sx={[style, { display: 'flex', justifyContent: 'center' }]}>
        <Tooltip title={componentName} arrow>
          <IconButton onClick={() => handleIconSelect(iconName)}>
            <Icon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <>
      <Tooltip title="Alterar ícone">
        <IconButton onClick={handleClick}>
          <LibraryAdd />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          label="Pesquisar ícone"
          type="text"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ margin: '10px' }}
        />
        <AutoSizer disableHeight>
          {({ width }) => (
            <Grid
              columnCount={3}
              columnWidth={width / 3}
              height={400}
              rowCount={Math.ceil(filteredIcons.length / 4)}
              rowHeight={70}
              width={width}
            >
              {Cell}
            </Grid>
          )}
        </AutoSizer>
      </Popover>
    </>
  );
};

export default React.memo(IconSelector);
