import { notify } from '@core/utils/notifyUtils';
import axios from 'axios';

export interface Address {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: string;
}

const VIA_CEP_BASE_URL = 'https://viacep.com.br/ws';

export const fetchAddressByCep = async (cep: string): Promise<Address> => {
  try {
    const response = await axios.get<Address>(
      `${VIA_CEP_BASE_URL}/${cep}/json`,
    );

    if (response.data.erro) {
      notify('warning', 'CEP não encontrado. Verifique e tente novamente.');
      throw new Error('CEP não encontrado.');
    }

    return response.data;
  } catch (error) {
    throw new Error(`Erro ao buscar CEP: ${error}`);
  }
};
