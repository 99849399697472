import { ReactNode } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSettings } from '@ilogix/hooks/useSettings';

interface Props {
  children: ReactNode;
}

const AlphaTheme = ({ children }: Props) => {
  const { settings } = useSettings();
  let activeTheme = { ...settings.themes[settings.activeTheme] };

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AlphaTheme;
