type ValidationErrors = {
  [key: string]: string;
};

export function useFormErrorHandler(
  setFormikErrors: (errors: ValidationErrors) => void,
) {
  const handleValidationError = (response: any) => {
    if (response && response.response && response.response.data) {
      const validationErrors: ValidationErrors = {};
      const errorData = response.response.data.response;

      for (const key in errorData) {
        if (Object.prototype.hasOwnProperty.call(errorData, key)) {
          validationErrors[key] = errorData[key];
        }
      }

      setFormikErrors(validationErrors);
    }
  };

  return {
    handleValidationError,
  };
}
