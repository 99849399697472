import React, { useCallback, useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { AlphaBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import { CreateList } from '@core/components/DataTable';
import useTable from '@core/hooks/useTable';
import LogModalBody from '../components/LogModalBody';
import { CustomCellJsonView } from '../components/LogCustomCells';
import { TableRow } from '@core/components/DataTable/CreateList';
import { LogListParams } from '../services/logService';

const methodFilterOptions = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
];

const bodyFilterOptions = [
  { label: 'Sim', value: '1' },
  { label: 'Não', value: '0' },
];

const LogListPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { columns, actions, updateColumns, isLoadingStructure } = useTable({
    endpoint: 'UserLogList',
  });

  const updateTableColumns = useCallback(() => {
    updateColumns([
      { column: 'id', header: '#', size: 100, hideFilter: true },
      {
        column: 'path',
        enableClickToCopy: true,
        Cell: ({ row }: { row: TableRow<LogListParams> }) =>
          row.original.path == '' ? '-' : row.original.path,
      },
      {
        column: 'created_at',
        filterVariant: 'date',
        id: 'created_at@bt',
        Cell: ({ row }: { row: TableRow<LogListParams> }) => {
          if (row.original.created_at) {
            return new Date(row.original.created_at).toLocaleString();
          }
        },
      },
      {
        id: 'body',
        column: 'body',
        header: 'Conteúdo',
        accessorKey: 'body',
        filterVariant: 'select',
        selectOptions: [...bodyFilterOptions],
        Cell: ({ row }: { row: TableRow<LogListParams> }) =>
          row && row.original.body != '' && row.original.body != null ? (
            <CustomCellJsonView
              row={row}
              handleOpen={handleOpen}
              setRowData={setRowData}
              rowType="Body"
            />
          ) : (
            '-'
          ),
      },
      {
        id: 'param',
        column: 'param',
        header: 'Parametros',
        accessorKey: 'param',
        filterVariant: 'select',
        selectOptions: [...bodyFilterOptions],
        Cell: ({ row }: { row: TableRow<LogListParams> }) =>
          row && row.original.param != '[]' ? (
            <CustomCellJsonView
              row={row}
              handleOpen={handleOpen}
              setRowData={setRowData}
              rowType="Param"
            />
          ) : (
            '-'
          ),
      },
      {
        column: 'method',
        filterVariant: 'select',
        selectOptions: [...methodFilterOptions],
      },
    ]);
  }, [updateColumns]);

  useEffect(() => {
    if (!isLoadingStructure) {
      updateTableColumns();
    }
  }, [isLoadingStructure, updateColumns]);

  return (
    <AlphaBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Log de Acesso' },
        ]}
      />
      <Box mt={6}>
        {isLoadingStructure ? (
          <LinearProgress />
        ) : (
          <CreateList
            tableEndpoint="UserLogList"
            menuItems={actions}
            columns={columns}
            exportOptions={{ fileName: 'Log de Acesso' }}
          />
        )}
      </Box>
      {open && (
        <LogModalBody rowData={rowData} open={open} onClose={handleClose} />
      )}
    </AlphaBox>
  );
};

export default LogListPage;
