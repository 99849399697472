import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import App from './App';

import queryClient from '@core/lib/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';

const rootElement = document.getElementById('root');
const root = createRoot(
  rootElement ? rootElement : document.createElement('div'),
);

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </StyledEngineProvider>,
);
