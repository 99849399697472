import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Card,
} from '@mui/material';
import { fetchUserLastLogin, UserParams } from '../services/userService';
import { useAlphaModal } from '@core/components/Modal/AlphaModal';
import { useQuery } from '@tanstack/react-query';
import { formatDateBR } from '@core/utils/formatUtils';

interface ModalProps {
  row?: UserParams;
  open: boolean;
  onClose: () => void;
}

const UserInfoModal = ({ row, open, onClose }: ModalProps) => {
  const AlphaModal = useAlphaModal();

  const { data: lastLogins } = useQuery({
    queryKey: ['lastLogins', row?.id],
    queryFn: () => (row?.id ? fetchUserLastLogin(row?.id) : undefined),
    enabled: !!row,
  });

  return (
    <AlphaModal.Root
      open={open}
      onClose={onClose}
      title="Informações do Usuário"
      styleProps={{ width: '80%', height: '90%' }}
    >
      <AlphaModal.Content>
        {row ? (
          <>
            <Card elevation={5} sx={{ p: 2 }}>
              <Typography fontSize={17} pb={1}>
                <strong>Nome:</strong> {row.name || 'Informação indisponível'}
              </Typography>
              <Typography fontSize={17} pb={1}>
                <strong>Email:</strong> {row.email || 'Informação indisponível'}
              </Typography>
              <Typography fontSize={17} pb={1}>
                <strong>Grupo:</strong>{' '}
                {row.group_name || 'Informação indisponível'}
              </Typography>
              <Typography fontSize={17} pb={1}>
                <strong>Status:</strong> {row.active ? 'Ativo' : 'Inativo'}
              </Typography>
            </Card>

            <Box mt={3}>
              <Typography variant="h6">Últimos Logins</Typography>
              {lastLogins && lastLogins.length > 0 ? (
                <Paper elevation={1} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ paddingLeft: 1 }}>
                          <strong>Data/Hora</strong>
                        </TableCell>
                        <TableCell>
                          <strong>IP</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Navegador</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Sistema Operacional</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lastLogins.map((login, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ paddingLeft: 1 }}>
                            {formatDateBR(login.created_at)}
                          </TableCell>
                          <TableCell>{login.user_ip}</TableCell>
                          <TableCell>{login.browser}</TableCell>
                          <TableCell>{login.operation_system}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <Typography variant="body1" pt={2}>
                  Nenhum login encontrado.
                </Typography>
              )}
            </Box>
          </>
        ) : (
          <Typography variant="body1">Nenhum dado encontrado</Typography>
        )}
      </AlphaModal.Content>
      <AlphaModal.Footer>
        <AlphaModal.Action onClick={onClose} variant="text" color="error">
          Fechar
        </AlphaModal.Action>
      </AlphaModal.Footer>
    </AlphaModal.Root>
  );
};

export default UserInfoModal;
