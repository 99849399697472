import React from 'react';

import { AlphaSuspense } from '..';
import { useSettings } from '@ilogix/hooks/useSettings';
import { AlphaLayouts, AlphaLayoutsType } from './index';

const AlphaLayout: React.FC = (props) => {
  const { settings } = useSettings();
  const activeLayout = settings.activeLayout;
  const LayoutComponent = (AlphaLayouts as AlphaLayoutsType)[
    activeLayout as keyof AlphaLayoutsType
  ];

  return (
    <AlphaSuspense>
      {LayoutComponent && <LayoutComponent {...props} />}
    </AlphaSuspense>
  );
};

export default AlphaLayout;
