import { fetchTableStructure } from '@core/services/tableService';
import { useQuery } from '@tanstack/react-query';
import { MRT_ColumnDef } from 'material-react-table';
import { useCallback, useState, useEffect } from 'react';

type Column = Partial<MRT_ColumnDef<any>> & {
  column: string;
  hideFilter?: boolean;
  selectOptions?: any;
};

type Action = {
  route?: string;
  getLabel?: (row: any) => string;
  action?: (row: any) => void;
  getIcon?: (row: any) => JSX.Element;
  props?: any;
  menuItemProps?: any;
};

type Actions = { [label: string]: Action };

type UseTableProps = {
  endpoint: string;
  initialColumns?: Column[];
  staleTime?: number;
};

const useTable = ({
  endpoint,
  initialColumns = [],
  staleTime = 1000 * 60 * 20,
}: UseTableProps) => {
  const [columns, setColumns] = useState<Column[]>(initialColumns);
  const [actions, setActions] = useState<Actions | undefined>();

  const { data: structure, isLoading: isLoadingStructure } = useQuery({
    queryKey: ['@structure_', endpoint],
    queryFn: () => fetchTableStructure(endpoint),
    enabled: !!endpoint,
    staleTime,
  });

  useEffect(() => {
    if (structure) {
      const newMenus: Actions = {};
      const newColumns = structure
        .map((column: any) => {
          if (column.component && column.component.length > 0) {
            column.component.forEach((component: any) => {
              newMenus[component.label] = {
                getLabel:
                  component.name === '' ? undefined : () => component.name,
                route: component.path || '/',
              };
            });
            return null;
          }
          const initialColumn =
            initialColumns.find((col) => col.column === column.label) || {};
          return {
            id: column.label,
            column: column.label,
            header: column.name,
            accessorKey: column.label,
            ...initialColumn,
          };
        })
        .filter(Boolean) as Column[];

      setColumns(newColumns);
      setActions((prevActions) => ({ ...prevActions, ...newMenus }));
    }
  }, [structure]);

  const updateColumns = useCallback((updates: Column[]) => {
    setColumns((prev) =>
      prev.map((col) => {
        const updatedCol: any =
          updates.find((update) => update.column === col.column) || {};
        return {
          ...col,
          ...updatedCol,
          id: updatedCol?.id || col.id || col.column,
        };
      }),
    );
  }, []);

  const updateActions = useCallback((newActions: Partial<Actions>) => {
    setActions((prevActions) => {
      if (!prevActions) return newActions as Actions;
      return Object.entries(newActions).reduce(
        (updatedActions, [key, value]) => {
          updatedActions[key] = {
            ...prevActions[key],
            ...value,
          };
          return updatedActions;
        },
        { ...prevActions },
      );
    });
  }, []);

  return {
    columns,
    actions,
    updateColumns,
    updateActions,
    isLoadingStructure,
  };
};

export default useTable;
