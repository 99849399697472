import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const StyledBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

interface BoxProps {
  children: React.ReactNode;
  className?: string;
}

const AlphaBox: React.FC<BoxProps> = ({ children, className }) => {
  return <StyledBox className={clsx(className)}>{children}</StyledBox>;
};

export default AlphaBox;
