import { Box } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const CustomCheckbox = ({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };
  return (
    <StyledWrapper>
      <Box
        sx={{
          display: 'flex',
          width: `${checked ? '20px' : '0px'}`,
          height: `${checked ? '20px' : '0px'}`,
        }}
      >
        <input
          checked={checked}
          type="checkbox"
          onChange={handleChange}
          style={{ position: 'absolute', transform: 'scale(0)' }}
        />
        <span className="checkbox" />
      </Box>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  label {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  input[type='checkbox'] {
    position: absolute;
    transform: scale(0);
  }

  input[type='checkbox']:checked ~ .checkbox {
    transform: rotate(45deg);
    width: 14px;
    margin-left: 5px;
    border-color: #fff;
    border-width: 5px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
  }

  .checkbox {
    display: block;
    width: inherit;
    height: inherit;
    border: solid 3px #2a2a2ab7;
    border-radius: 6px;
    transition: all 0.375s;
    margin-top: -5px;
  }
`;

export default CustomCheckbox;
