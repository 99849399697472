import { Box, Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const JustifyBox = styled(FlexBox)(({ theme }) => ({
  maxWidth: 380,
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const NotFoundRoot = styled(FlexBox)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 4),
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundRoot>
      <JustifyBox>
        <ErrorOutlineIcon sx={{ fontSize: 80, color: 'primary.main', mb: 2 }} />
        <Typography variant="h1" color="primary" fontWeight={600}>
          404
        </Typography>

        <Typography variant="h5" color="text.secondary" fontWeight={400} mb={2}>
          Página não encontrada
        </Typography>

        <Typography variant="body1" color="text.secondary" mb={4}>
          Parece que você se perdeu! A página que você está procurando não
          existe.
        </Typography>

        <StyledButton
          color="primary"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Voltar
        </StyledButton>
      </JustifyBox>
    </NotFoundRoot>
  );
};

export default NotFound;
