import { useUserRoutesComponents } from '@ilogix/hooks/useRoute';
import { useMemo } from 'react';

const useComponent = (path: string) => {
  const userRoutes = useUserRoutesComponents();

  const route = useMemo(() => {
    return userRoutes.find((route) => route.path === path);
  }, [userRoutes, path]);

  const component = {
    visible: !!route,
    name: route?.name || '',
  };

  return component;
};

export default useComponent;
