import { useEffect } from 'react';
import {
  loginAuth,
  activeAccountAuth,
  forgotPasswordAuth,
} from '@ilogix/views/auth/services';
import useAuthStore from '@core/store/authStore';
import { fetchUserRoutes } from '@core/services/routeService';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { logoutAuth } from '@ilogix/views/auth/services/authService';

export interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | number;
}

export interface MostAccessedFunctionalities {
  id: number;
  functionality_id: number;
  path: string;
  created_at: string;
  updated_at: string | null;
}

interface TokenPayload extends JwtPayload {
  id: string;
  name: string;
  email: string;
  groupId: string | number;
  mostAccessedFunctionalities: MostAccessedFunctionalities[];
}

const setSession = (accessToken: string): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('redirectUrl');
  }
};

const decodeToken = (
  accessToken: string,
): {
  user: User;
  mostAccessedFunctionalities: MostAccessedFunctionalities[];
} => {
  const decodedToken = jwtDecode<TokenPayload>(accessToken);
  const { id, name, email, groupId, mostAccessedFunctionalities } =
    decodedToken;

  const user: User = { id, name, email, groupId };
  return { user, mostAccessedFunctionalities };
};

const useAuth = () => {
  const { init, login, logout, accessToken } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const storedAccessToken = accessToken;
      if (storedAccessToken) {
        const { user, mostAccessedFunctionalities } =
          decodeToken(storedAccessToken);
        init({
          isAuthenticated: true,
          user,
          accessToken: storedAccessToken,
          mostAccessedFunctionalities,
        });
      } else {
        init({
          isAuthenticated: false,
          user: null,
          accessToken: null,
          mostAccessedFunctionalities: [],
        });
      }
    })();
  }, [accessToken, init]);

  const handleLogin = async (
    email: string,
    password: string,
    userLoginToken: string,
  ): Promise<void> => {
    const jwtToken = await loginAuth(email, password, userLoginToken);

    const { response } = jwtToken.data;

    const { user } = decodeToken(response);

    login(user, response);

    await fetchUserRoutes();
  };

  const handleActivateAccount = async (password: string): Promise<void> => {
    await activeAccountAuth(password);
  };

  const handleForgotPassword = async (email: string): Promise<void> => {
    await forgotPasswordAuth(email);
  };

  const handleLogout = async () => {
    setSession('');
    localStorage.removeItem('routes');
    await logoutAuth();
    logout();
    navigate('/session/signin', { replace: true, state: { from: '/' } });
  };

  return {
    handleLogin,
    handleLogout,
    handleActivateAccount,
    handleForgotPassword,
  };
};

export { useAuth };
