import Grid from '@mui/material/Grid2';
import { AlphaBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  editLabelParams,
  editLabels,
  fetchLabels,
  fetchModules,
  Functionality,
  ModuleParams,
} from '../services/labelService';
import IconSelector from '../components/IconSelector';
import * as Icons from '@mui/icons-material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useNavigate } from 'react-router-dom';

const renderIcon = (iconName: string) => {
  const pascalCase = iconName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');

  const IconComponent = (Icons as any)[pascalCase];

  return IconComponent ? <IconComponent sx={{ opacity: 0.7 }} /> : null;
};

const LabelManagePage = () => {
  const navigate = useNavigate();
  const [moduleId, setModuleId] = useState<number | null>(null);
  const [moduleName, setModuleName] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const [functionalitiesData, setFunctionalitiesData] = useState<
    Functionality[]
  >([]);
  const [functionalityId, setFunctionalityId] = useState<number | null>(null);
  const [functionalityName, setFunctionalityName] = useState('');
  const [functionalityIcon, setFunctionalityIcon] = useState('');
  const [components, setComponents] = useState<
    Array<{ id: number; name: string; icon: string; component_type_id: number }>
  >([]);
  const [tableColumns, setTableColumns] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [changedData, setChangedData] = useState<any>({
    module: [],
    functionality: [],
    component: [],
    table_column: [],
  });

  const { data: modules, isLoading: isLoadingModules } = useQuery({
    queryKey: ['modules'],
    queryFn: () => fetchModules(),
  });

  const { data: dataLabels, isLoading } = useQuery({
    queryKey: ['labels', moduleId],
    queryFn: () => fetchLabels(moduleId),
    enabled: !!moduleId,
  });

  useEffect(() => {
    if (dataLabels && dataLabels[0].functionality && !isLoading) {
      setFunctionalitiesData(dataLabels[0].functionality);
    }
  }, [dataLabels, isLoading]);

  const handleModuleChange = (newValue: ModuleParams | null) => {
    if (newValue) {
      setModuleId(newValue.id);
      setModuleName(newValue.name);
      setModuleIcon(newValue.icon);
      setChangedData((prev: any) => ({
        ...prev,
        module: [
          ...prev.module.filter((mod: any) => mod.id !== newValue.id),
          { id: newValue.id, name: newValue.name, icon: newValue.icon },
        ],
      }));
    } else {
      setModuleId(null);
      setModuleName('');
      setModuleIcon('');
      setChangedData((prev: any) => ({
        ...prev,
        module: prev.module.filter((mod: any) => mod.id !== moduleId),
      }));
    }
  };

  const handleModuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setModuleName(newName);
    if (moduleId) {
      setChangedData((prev: any) => ({
        ...prev,
        module: prev.module.map((mod: any) =>
          mod.id === moduleId ? { ...mod, name: newName } : mod,
        ),
      }));
    }
  };

  const handleModuleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newIcon = e.target.value;
    setModuleIcon(newIcon);
    if (moduleId) {
      setChangedData((prev: any) => {
        const moduleArray = Array.isArray(prev.module) ? prev.module : [];
        const moduleIndex = moduleArray.findIndex(
          (mod: any) => mod.id === moduleId,
        );

        if (moduleIndex !== -1) {
          const updatedModules = [...moduleArray];
          updatedModules[moduleIndex] = {
            ...updatedModules[moduleIndex],
            icon: newIcon,
          };
          return { ...prev, module: updatedModules };
        }
      });
    }
  };

  const handleFunctionalityChange = (newValue: Functionality | null) => {
    if (newValue) {
      setFunctionalityId(newValue.id);
      setFunctionalityName(newValue.name);
      setFunctionalityIcon(newValue.icon || '');
      setChangedData((prev: any) => ({
        ...prev,
        functionality: [
          ...prev.functionality.filter((func: any) => func.id !== newValue.id),
          { id: newValue.id, name: newValue.name, icon: newValue.icon },
        ],
      }));

      setComponents(newValue.component);
      setTableColumns(newValue.table_column);
    } else {
      setFunctionalityId(null);
      setFunctionalityName('');
      setFunctionalityIcon('');
      setChangedData((prev: any) => ({
        ...prev,
        functionality: [],
        component: [],
        table_column: [],
      }));
    }
  };

  const handleFunctionalityNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newName = e.target.value;
    setFunctionalityName(newName);
    if (functionalityId) {
      setChangedData((prev: any) => ({
        ...prev,
        functionality: prev.functionality.map((func: any) =>
          func.id === functionalityId ? { ...func, name: newName } : func,
        ),
      }));
    }
  };

  const handleFunctionalityIconChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newIcon = e.target.value;
    setFunctionalityIcon(newIcon);
    if (functionalityId) {
      setChangedData((prev: any) => {
        const functionalityArray = Array.isArray(prev.functionality)
          ? prev.functionality
          : [];
        const functionalityIndex = functionalityArray.findIndex(
          (func: any) => func.id === functionalityId,
        );

        if (functionalityIndex !== -1) {
          const updatedFunctionalities = [...functionalityArray];
          updatedFunctionalities[functionalityIndex] = {
            ...updatedFunctionalities[functionalityIndex],
            icon: newIcon,
          };
          return { ...prev, functionality: updatedFunctionalities };
        }
      });
    }
  };

  const handleComponentChange = (
    index: number,
    field: 'name' | 'icon',
    value: string,
    component_type_id: number,
  ) => {
    const realIndex = components.findIndex(
      (comp) =>
        comp.component_type_id === component_type_id &&
        comp.name ===
          components.filter((c) => c.component_type_id === component_type_id)[
            index
          ].name,
    );

    setComponents((prev) => {
      const newComponents = [...prev];
      if (newComponents[realIndex]) {
        newComponents[realIndex] = {
          ...newComponents[realIndex],
          [field]: value,
        };
      }
      return newComponents;
    });

    setChangedData((prev: any) => {
      const updatedComponent = { ...components[realIndex], [field]: value };
      const existingIndex = prev.component.findIndex(
        (c: any) => c.id === updatedComponent.id,
      );

      let newComponentArray;
      if (existingIndex >= 0) {
        newComponentArray = prev.component.map((c: any, i: any) =>
          i === existingIndex
            ? { id: c.id, name: field === 'name' ? value : c.name }
            : c,
        );
      } else {
        newComponentArray = [
          ...prev.component,
          { id: updatedComponent.id, name: updatedComponent.name },
        ];
      }

      return {
        ...prev,
        component: newComponentArray,
      };
    });
  };

  const handleTableColumnChange = (index: number, value: string) => {
    setTableColumns((prev) => {
      const newTableColumns = [...prev];
      if (newTableColumns[index]) {
        newTableColumns[index] = { ...newTableColumns[index], name: value };
      }
      return newTableColumns;
    });
    setChangedData((prev: any) => {
      const updatedColumn = tableColumns[index];
      const existingIndex = prev.table_column.findIndex(
        (c: any) => c.id === updatedColumn.id,
      );

      let newColumnArray;
      if (existingIndex >= 0) {
        newColumnArray = prev.table_column.map((c: any, i: any) =>
          i === existingIndex ? { id: c.id, name: value } : c,
        );
      } else {
        newColumnArray = [
          ...prev.table_column,
          { id: updatedColumn.id, name: value },
        ];
      }

      return {
        ...prev,
        table_column: newColumnArray,
      };
    });
  };

  const handleIconSelect =
    (field: 'module' | 'functionality', index?: number) =>
    (iconName: string) => {
      switch (field) {
        case 'module':
          handleModuleIconChange({
            target: { value: iconName },
          } as React.ChangeEvent<HTMLInputElement>);
          break;
        case 'functionality':
          handleFunctionalityIconChange({
            target: { value: iconName },
          } as React.ChangeEvent<HTMLInputElement>);
          break;
      }
    };

  const mutationUpdate = useMutation({
    mutationFn: ({ values }: { values: editLabelParams }) => editLabels(values),
    onSuccess: () => {
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error updating labels:', error);
    },
  });

  const handleSave = () => {
    mutationUpdate.mutate({ values: changedData });
  };

  useEffect(() => {
    if (moduleId === null) {
      setFunctionalityId(null);
      setFunctionalityName('');
      setFunctionalityIcon('');
      setFunctionalitiesData([]);
      setComponents([]);
      setTableColumns([]);
      setChangedData({
        module: [],
        functionality: [],
        component: [],
        table_column: [],
      });
    }
  }, [moduleId]);

  useEffect(() => {
    if (functionalityId === null) {
      setFunctionalityId(null);
      setFunctionalityName('');
      setFunctionalityIcon('');
      setComponents([]);
      setTableColumns([]);
      setChangedData((prev: any) => ({
        module: [...prev.module],
        functionality: [],
        component: [],
        table_column: [],
      }));
    }
  }, [functionalityId]);

  return (
    <AlphaBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Gerenciar Labels' },
        ]}
      />
      <Box mt={3} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h4">Gerenciar Labels</Typography>
        <Button variant="contained" sx={{ width: 150 }} onClick={handleSave}>
          Salvar
        </Button>
      </Box>
      <Grid container spacing={3} alignItems="center" mb={2} mt={4}>
        {/* Módulo */}
        <Grid size={12}>
          <Card elevation={2} sx={{ padding: 4, borderRadius: 2 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom mb={4}>
              Módulo
            </Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              <Autocomplete
                style={{ width: 600 }}
                id="group_id"
                options={modules || []}
                loading={isLoadingModules}
                getOptionLabel={(option: ModuleParams) => option.name || ''}
                onChange={(_, newValue) => handleModuleChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Módulo" variant="outlined" />
                )}
              />
              <TextField
                fullWidth
                id="name_module"
                label="Nome"
                value={moduleName || ''}
                onChange={handleModuleNameChange}
                variant="outlined"
                disabled={!moduleId}
              />
              {moduleId !== 1 && (
                <TextField
                  style={{ width: 600 }}
                  id="icon_module"
                  label="Ícone"
                  value={moduleIcon || ''}
                  onChange={handleModuleIconChange}
                  variant="outlined"
                  disabled={!moduleId}
                  slotProps={{
                    input: {
                      startAdornment: moduleIcon && (
                        <InputAdornment position="start">
                          {renderIcon(moduleIcon)}
                        </InputAdornment>
                      ),
                      endAdornment: moduleId && (
                        <IconSelector
                          onSelectIcon={handleIconSelect('module')}
                        />
                      ),
                    },
                  }}
                />
              )}
            </Box>
          </Card>
        </Grid>

        {/* Funcionalidade */}
        {functionalitiesData && functionalitiesData.length > 0 && (
          <Grid size={12}>
            <Card elevation={2} sx={{ padding: 4, borderRadius: 2 }}>
              <Typography variant="h5" fontWeight="bold" gutterBottom mb={4}>
                Funcionalidade
              </Typography>
              <Box display="flex" flexDirection="row" gap={2}>
                <Autocomplete
                  style={{ width: 600 }}
                  id="functionality_id"
                  options={functionalitiesData}
                  getOptionLabel={(option: Functionality) => option.name || ''}
                  onChange={(_, newValue) =>
                    handleFunctionalityChange(newValue)
                  }
                  disabled={!moduleId || isLoading}
                  loading={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Funcionalidade"
                      variant="outlined"
                    />
                  )}
                />
                <TextField
                  fullWidth
                  id="name_functionality"
                  label="Nome"
                  value={functionalityName}
                  onChange={handleFunctionalityNameChange}
                  disabled={!functionalityId}
                  variant="outlined"
                />
                <TextField
                  style={{ width: 600 }}
                  id="icon_functionality"
                  label="Ícone"
                  value={functionalityIcon}
                  onChange={handleFunctionalityIconChange}
                  disabled={!functionalityId}
                  variant="outlined"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {functionalityIcon
                            ? renderIcon(functionalityIcon)
                            : null}
                        </InputAdornment>
                      ),
                      endAdornment: functionalityId && (
                        <IconSelector
                          onSelectIcon={handleIconSelect('functionality')}
                        />
                      ),
                    },
                  }}
                />
              </Box>
            </Card>
          </Grid>
        )}
        {/* Componentes */}
        {components && components.length > 0 && (
          <Grid size={12}>
            <Card elevation={2} sx={{ padding: 4, borderRadius: 2 }}>
              <Typography variant="h5" fontWeight="bold" gutterBottom mb={4}>
                Componentes
              </Typography>
              <Box display="flex" flexDirection="row" gap={4} ml={2}>
                <Box flex={1}>
                  <Typography
                    variant="h6"
                    fontWeight="semibold"
                    gutterBottom
                    mb={2}
                  >
                    Tipo 1 - Botões
                  </Typography>
                  {components
                    .filter((component) => component.component_type_id === 1)
                    .map((component, index) => (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        mb={2}
                      >
                        <TextField
                          fullWidth
                          label="Nome"
                          value={component.name}
                          onChange={(e) =>
                            handleComponentChange(
                              index,
                              'name',
                              e.target.value,
                              1,
                            )
                          }
                          disabled={!functionalityId}
                          variant="outlined"
                        />
                      </Box>
                    ))}
                </Box>
                <Box flex={1}>
                  <Typography
                    variant="h6"
                    fontWeight="semibold"
                    gutterBottom
                    mb={2}
                  >
                    Tipo 2 - Ações
                  </Typography>
                  {components
                    .filter((component) => component.component_type_id === 2)
                    .map((component, index) => (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        mb={2}
                      >
                        <TextField
                          fullWidth
                          label="Nome"
                          value={component.name}
                          onChange={(e) =>
                            handleComponentChange(
                              index,
                              'name',
                              e.target.value,
                              2,
                            )
                          }
                          disabled={!functionalityId}
                          variant="outlined"
                        />
                      </Box>
                    ))}
                </Box>
              </Box>
            </Card>
          </Grid>
        )}

        {/* Tabela */}
        {tableColumns && tableColumns.length > 0 && (
          <Grid size={12}>
            <Card elevation={2} sx={{ padding: 4, borderRadius: 2 }}>
              <Typography variant="h5" fontWeight="bold" gutterBottom mb={4}>
                Colunas
              </Typography>
              {tableColumns.map((column, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  alignItems="center"
                  pl={2}
                  mb={2}
                >
                  <TextField
                    style={{ width: 600 }}
                    label="Nome"
                    value={column.name}
                    onChange={(e) =>
                      handleTableColumnChange(index, e.target.value)
                    }
                    disabled={!functionalityId}
                    variant="outlined"
                  />
                </Box>
              ))}
            </Card>
          </Grid>
        )}
        {!moduleId && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            width={'100%'}
          >
            <Typography
              variant="body1"
              mb={3}
              color="text.secondary"
              maxWidth="600px"
            >
              Bem-vindo à seção de gerenciamento de labels. Aqui você pode
              modificar nomes e icones de modulos, funcionalidades, componentes
              e colunas.
            </Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <InfoRoundedIcon color="info" sx={{ mr: 1 }} />
              <Typography variant="body2" color="info.main">
                Selecione um módulo no painel acima para começar a editar.
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
      <Box mt={3} display={'flex'} justifyContent={'end'}>
        {components && components.length > 0 && (
          <Button
            variant="contained"
            sx={{ width: 200, fontSize: 16 }}
            onClick={handleSave}
          >
            Salvar
          </Button>
        )}
      </Box>
    </AlphaBox>
  );
};

export default LabelManagePage;
