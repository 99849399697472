import {
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  Typography,
  styled,
  useTheme,
  Tooltip,
  Paper,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { useEffect, useState } from 'react';
import { AllFunctionalitiesChildrenParams } from '../services/groupService';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

interface FunctionalityDetailsProps {
  children: AllFunctionalitiesChildrenParams[];
  child: any;
  setChild: any;
  setChildEdit: any;
}

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(3),
}));

const BoxCard = styled(Box)(({ theme }: { theme: any }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#ECECEC',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(1),
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(2),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 'auto',
  maxWidth: 220,
}));

const FunctionalityDetails = ({
  children,
  child,
  setChild,
  setChildEdit,
}: FunctionalityDetailsProps) => {
  const [items, setItems] = useState(children);
  const theme = useTheme();

  useEffect(() => {
    if (children && children.length > 0) {
      const initialVisibility = initializeVisibility();
      setVisibility(initialVisibility);
      initializeChildState(initialVisibility);
    }
  }, [children]);

  const initializeVisibility = () => {
    let maxOrder = 0;

    children.forEach((item) => {
      if (item.order !== null) {
        maxOrder = Math.max(maxOrder, item.order);
      }
    });
    const initialVisibility: {
      [key: number]: {
        id: number;
        order: number;
        visible: boolean;
      };
    } = {};

    children.forEach((item, index) => {
      const existingItem = child
        ? child.find((c: any) => c.id === item.id)
        : null;

      let order = item.order;
      if (order === null || existingItem?.order === null) {
        maxOrder += 1;
        order = maxOrder;
      }
      initialVisibility[item.id] = {
        id: item.id,
        order: order,
        visible: item.visible,
      };
    });
    return initialVisibility;
  };

  const initializeChildState = (initialVisibility: {
    [key: number]: {
      id: number;
      order: number;
      visible: boolean;
    };
  }) => {
    const updatedChildState = children.map((item) => {
      const existingItem = child?.find((c: any) => c.id === item.id);
      return {
        id: item.id,
        order:
          existingItem?.order || item.order || initialVisibility[item.id].order,
        visible: existingItem?.visible ?? initialVisibility[item.id].visible,
      };
    });

    setChild((prevChild: any) => {
      const mergedChild = [...(prevChild || [])];
      updatedChildState.forEach((newItem) => {
        const index = mergedChild.findIndex((c: any) => c.id === newItem.id);
        if (index !== -1) {
          mergedChild[index] = newItem;
        } else {
          mergedChild.push(newItem);
        }
      });
      return mergedChild;
    });
  };

  const [visibility, setVisibility] = useState<{
    [key: number]: {
      id: number;
      order: number;
      visible: boolean;
    };
  }>(initializeVisibility);

  const handleCheckboxChange = (id: number) => {
    setVisibility((prevVisibility: any) => {
      const newVisibility = {
        ...prevVisibility,
        [id]: {
          ...prevVisibility[id],
          visible: !prevVisibility[id].visible,
        },
      };

      setChild((prevChild: any) => {
        return prevChild.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              visible: newVisibility[id].visible,
            };
          }
          return item;
        });
      });

      return newVisibility;
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);

    setChild((prevChild: any) => {
      const updatedChild = prevChild.map((existingItem: any) => {
        const newIndex = newItems.findIndex(
          (item) => item.id === existingItem.id,
        );
        return {
          ...existingItem,
          order: newIndex !== -1 ? newIndex + 1 : existingItem.order,
        };
      });
      return updatedChild;
    });

    setVisibility((prevVisibility: any) => {
      const updatedVisibility = { ...prevVisibility };
      newItems.forEach((item, index) => {
        if (updatedVisibility[item.id]) {
          updatedVisibility[item.id] = {
            ...updatedVisibility[item.id],
            order: index + 1,
          };
        }
      });
      return updatedVisibility;
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <StyledAccordionDetails
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items
              .sort(
                (a, b) =>
                  (visibility[a.id].order ?? 0) - (visibility[b.id].order ?? 0),
              )
              .map((item, index) => {
                const childItem = visibility[item.id];
                return (
                  <Draggable
                    key={item.name}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        elevation={snapshot.isDragging ? 6 : 1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderRadius: '8px',
                        }}
                      >
                        <BoxCard>
                          <Box display="flex" alignItems="center">
                            <DragIndicatorRoundedIcon
                              sx={{
                                cursor: 'grab',
                                color:
                                  theme.palette.mode === 'dark'
                                    ? theme.palette.grey[700]
                                    : theme.palette.grey[500],
                              }}
                            />
                            <Checkbox
                              icon={<VisibilityOffOutlinedIcon />}
                              checkedIcon={<VisibilityOutlinedIcon />}
                              checked={childItem?.visible ?? false}
                              onChange={() => handleCheckboxChange(item.id)}
                              sx={{
                                marginLeft: '-10px',
                              }}
                            />
                            <Tooltip title={item.name} arrow>
                              <CardTitle>{item.name}</CardTitle>
                            </Tooltip>
                          </Box>
                          <Box
                            justifyContent="flex-end"
                            alignContent={'center'}
                          >
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() => setChildEdit(item)}
                            >
                              Alterar
                            </Button>
                          </Box>
                        </BoxCard>
                      </Paper>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </StyledAccordionDetails>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FunctionalityDetails;
