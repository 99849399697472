import { Avatar, Box, styled, useTheme } from '@mui/material';

type ChatAvatarProps = {
  src: string;
  status: 'online' | 'offline';
};

export default function ChatAvatar({ src, status }: ChatAvatarProps) {
  const StyledAvatar = styled(Avatar)({
    height: 40,
    width: 40,
  });

  const StatusCircle = styled('div')(
    ({ theme }) => ({
      height: 14,
      width: 14,
      bottom: 0,
      right: '-3px',
      borderRadius: '7px',
      position: 'absolute',
      border: `2px solid ${theme.palette.common.white}`,
      background:
        status === 'online'
          ? theme.palette.primary.main
          : theme.palette.error.main,
      color: status !== 'online' ? 'white !important' : 'initial',
    }),
    { name: 'Mui-StatusCircle' },
  );

  const theme = useTheme(); // Adicione isso para obter o objeto theme

  return (
    <Box position="relative">
      <StyledAvatar src={src} />
      <StatusCircle theme={theme} />
    </Box>
  );
}
