import useAuthStore from '@core/store/authStore';
import { routeCheck } from '@core/data/roles';
import { useUserRoutes } from '@ilogix/hooks/useRoute';
import { useCallback, useMemo } from 'react';
import useRoutesIlogix from '@ilogix/routes';

const AuthorizedPaths = () => {
  const { isAuthenticated } = useAuthStore();
  const routes = useUserRoutes();
  const routesIlogix = useRoutesIlogix();

  const combinedRoutes = useMemo(() => routesIlogix, [routes]);

  const authorizedPath = useCallback(
    () =>
      routeCheck(
        combinedRoutes,
        {
          isAuthenticated,
          userRoutes: routes,
        },
        false,
      ),
    [combinedRoutes, routes, isAuthenticated],
  );

  return authorizedPath() || [];
};

export default AuthorizedPaths;
