import React, { Suspense } from 'react';
import { AlphaLoading } from '.';

const Loadable =
  <T extends React.ComponentType<any>>(Component: T) =>
  (props: React.ComponentProps<T>) => {
    return (
      <Suspense fallback={<AlphaLoading />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
