import React, { Fragment } from 'react';

import {
  Box,
  BoxProps,
  ButtonBase,
  Icon,
  styled,
  TypographyProps,
} from '@mui/material';
import { useSettings } from '@ilogix/hooks/useSettings';
import { NavLink } from 'react-router-dom';
import { Paragraph, Span } from '../Typography';
import AlphaVerticalNavExpansionPanel from './AlphaVerticalNavExpansionPanel';
import { Module } from '../Sidenav';
import FavoriteRouteCheckBox from './AlphaVerticalNavFavorites';

type ListLabelProps = {
  mode: string;
} & TypographyProps;

type InternalLinkProps = {
  mode: string;
} & BoxProps;

const ListLabel = styled(Paragraph)<ListLabelProps>(({ theme, mode }) => {
  return {
    fontSize: '12px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    visibility: mode === 'compact' ? 'hidden' : 'visible',
    color: theme.palette.text.secondary,
  };
});

const ListLabelModule = styled(Paragraph)<ListLabelProps>(({ theme, mode }) => {
  return {
    fontSize: '15px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    display: mode === 'compact' ? 'none' : 'block',
    color: theme.palette.text.secondary,
  };
});

const getExtAndIntCommon = (theme: any, mode: any) => ({
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '4px',
  height: 50,
  whiteSpace: 'pre',
  marginBottom: '0.2rem',
  textDecoration: 'none',
  justifyContent: 'space-between',
  transition: 'all 150ms ease-in',
  '&:hover': {
    background: theme.palette.action.hover,
  },
  '&.compactNavItem': {
    overflow: 'hidden',
    justifyContent: 'center !important',
  },
  '& .iconDefault': {
    fontSize: '28px',
    paddingLeft: '10px',
    paddingRight: '25px',
    verticalAlign: 'middle',
  },
  '& .icon': {
    fontSize: '25px',
    paddingLeft: mode === 'compact' ? '11px' : '18px',
    paddingRight: '25px',
    verticalAlign: 'middle',
  },
  '& .iconModule': {
    fontSize: '25px',
    paddingLeft: '11px',
    paddingRight: '25px',
    verticalAlign: 'middle',
  },
});

type ExternalLinkProps = {
  theme?: any;
  href?: string;
  className?: string;
  rel?: string;
  target?: string;
  children?: React.ReactNode;
  mode?: any;
};

const ExternalLink = styled('a')<ExternalLinkProps>(({ theme, ...props }) => ({
  ...getExtAndIntCommon(theme, props.mode),
  color: theme?.palette.text.primary,
  whiteSpace: 'pre',
})) as React.ComponentType<ExternalLinkProps>;

const InternalLink = styled(Box)<InternalLinkProps>(({ theme, ...props }) => ({
  '& a': {
    ...getExtAndIntCommon(theme, (props as any).mode),
    color: theme.palette.text.primary,
  },
  '& .navItemActive': {
    backgroundColor:
      theme?.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.1)'
        : 'rgba(255, 255, 255, 0.05)',
    color: theme.palette.secondary.main,
    boxShadow:
      theme?.palette.mode === 'light'
        ? '0px 2px 5px rgba(0, 0, 0, 0.2)'
        : '0px 4px 5px rgba(0, 0, 0, 0.6)',
  },
}));

type StyledTextProps = {
  mode?: string;
};

const StyledText = styled(Span)<StyledTextProps>(({ mode }) => ({
  fontSize: '0.875rem',
  paddingLeft: '0.8rem',
  display: mode === 'compact' ? 'none' : 'block',
}));

const BulletIcon = styled('div')(({ theme }) => ({
  padding: '2px',
  marginLeft: '26px',
  marginRight: '8px',
  overflow: 'hidden',
  borderRadius: '300px',
  background: theme.palette.text.primary,
}));

const BadgeValue = styled('div')(() => ({
  padding: '1px 8px',
  overflow: 'hidden',
  borderRadius: '300px',
}));

type AlphaVerticalNavProps = {
  items: any[];
  onModuleClick: (module: Module) => void;
};

const AlphaVerticalNav: React.FC<AlphaVerticalNavProps> = ({
  items,
  onModuleClick,
}) => {
  const { settings } = useSettings();
  const { mode } = settings.layout1Settings.leftSidebar;

  const renderLevels = (data: any[]) => {
    return data.map((item, index) => {
      if (item.type === 'label') {
        return (
          <ListLabel key={index} mode={mode} className="sidenavHoverShow">
            {item.label}
          </ListLabel>
        );
      }

      if (item.type === 'labelModule') {
        return (
          <ListLabelModule key={index} mode={mode} className="sidenavHoverShow">
            {item.label}
          </ListLabelModule>
        );
      }

      if (item.children) {
        return (
          <AlphaVerticalNavExpansionPanel mode={mode} item={item} key={index}>
            {renderLevels(item.children)}
          </AlphaVerticalNavExpansionPanel>
        );
      } else if (item.type === 'extLink') {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            className={`${mode === 'compact' && 'compactNavItem'}`}
            rel="noopener noreferrer"
            target="_blank"
            mode={mode}
          >
            <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
              {(() => {
                if (item.icon) {
                  return (
                    <Icon className={item.typeIcon ? `iconDefault` : 'icon'}>
                      {item.icon}
                    </Icon>
                  );
                } else {
                  return (
                    <span className="item-icon icon-text">{item.iconText}</span>
                  );
                }
              })()}
              <StyledText mode={mode} className="sidenavHoverShow">
                {item.name}
              </StyledText>
              <Box mx="auto"></Box>
              {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
            </ButtonBase>
          </ExternalLink>
        );
      } else if (item.type === 'module') {
        return (
          <InternalLink mode={mode} key={index}>
            <NavLink
              to="#"
              onClick={() => onModuleClick(item)}
              className={({ isActive }) =>
                !isActive
                  ? `navItemActive ${mode === 'compact' && 'compactNavItem'}`
                  : `${mode === 'compact' && 'compactNavItem'}`
              }
            >
              <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                {item?.icon ? (
                  <Icon className="iconModule" sx={{ width: 36 }}>
                    {item.icon}
                  </Icon>
                ) : (
                  <Fragment>
                    <BulletIcon
                      className={`nav-bullet`}
                      sx={{
                        ...(mode === 'compact' && {
                          display: 'none',
                        }),
                      }}
                    />
                    <Box
                      className="nav-bullet-text"
                      sx={{
                        ml: '20px',
                        fontSize: '11px',
                        ...(mode !== 'compact' && {
                          display: 'none',
                        }),
                      }}
                    >
                      {item.iconText}
                    </Box>
                  </Fragment>
                )}
                <StyledText mode={mode} className="sidenavHoverShow">
                  {item.name}
                </StyledText>

                <Box mx="auto" />

                {item.badge && (
                  <BadgeValue className="sidenavHoverShow">
                    {item.badge.value}
                  </BadgeValue>
                )}
              </ButtonBase>
            </NavLink>
          </InternalLink>
        );
      } else {
        return (
          <InternalLink mode={mode} key={index}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? `navItemActive ${mode === 'compact' && 'compactNavItem'}`
                  : `${mode === 'compact' && 'compactNavItem'}`
              }
            >
              <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                {item?.icon ? (
                  <Icon
                    className={item.typeIcon ? `iconDefault` : 'icon'}
                    sx={{ width: 36 }}
                  >
                    {item.icon}
                  </Icon>
                ) : (
                  <Fragment>
                    <BulletIcon
                      className={`nav-bullet`}
                      sx={{
                        ...(mode === 'compact' && {
                          display: 'none',
                        }),
                      }}
                    />
                    <Box
                      className="nav-bullet-text"
                      sx={{
                        ml: '20px',
                        fontSize: '10px',
                        ...(mode !== 'compact' && {
                          display: 'none',
                        }),
                      }}
                    >
                      {item.iconText}
                    </Box>
                  </Fragment>
                )}
                <StyledText mode={mode} className="sidenavHoverShow">
                  {item.name}
                </StyledText>

                <Box mx="auto" />

                <FavoriteRouteCheckBox mode={mode} item={item} />
              </ButtonBase>
            </NavLink>
          </InternalLink>
        );
      }
    });
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(AlphaVerticalNav);
