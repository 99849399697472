import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomMaskProps {
  mask: string;
  inputRef: (ref: HTMLInputElement | null) => void;
  onChange: (event: { target: { name: string; value: any } }) => void;
  name: string;
  [key: string]: any;
}

const CustomMask = React.forwardRef<HTMLElement, CustomMaskProps>(
  function CustomMask(props, ref) {
    const { mask, inputRef, onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref as any}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
      />
    );
  },
);

export default CustomMask;
