import { HeaderForm } from '@core/components/Form';
import { AlphaBox } from '@core/components/Layout';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  editGroup,
  fetchGroup,
  GroupParams,
  registerGroup,
} from '../services/groupService';

const groupSchema = z.object({
  name: z.string({ required_error: 'Nome é obrigatório' }),
});

const GroupFormPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isEditing = !!id;

  const mutationCreate = useMutation({
    mutationFn: ({ values }: { values: GroupParams }) => registerGroup(values),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error create group:', error);
    },
  });
  const mutationUpdate = useMutation({
    mutationFn: ({ id, values }: { id: string; values: GroupParams }) =>
      editGroup(id, values),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error updating group:', error);
    },
  });

  const { data: group } = useQuery<GroupParams>({
    queryKey: ['fetchGroup', id],
    queryFn: () => {
      if (id !== undefined) {
        return fetchGroup(id);
      }
      return Promise.reject('ID is undefined');
    },
    enabled: isEditing && id !== undefined,
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: toFormikValidationSchema(groupSchema),
    onSubmit: async (values) => {
      if (isEditing && id) {
        mutationUpdate.mutate({ id, values });
      } else {
        mutationCreate.mutate({ values });
      }
    },
  });

  useEffect(() => {
    if (group) {
      formik.setValues({
        name: group.name || '',
      });
    }
  }, [group]);

  return (
    <AlphaBox>
      <BreadcrumbForm
        routeSegments={[
          {
            name: 'Gerenciar Grupos',
            path: '/accessControl/manageGroup',
          },
        ]}
      />
      <Grid container spacing={3} alignItems="center" p={6}>
        <Grid size={{ xs: 12, md: 12 }}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {isEditing ? (
              <HeaderForm title={formik.values?.name} subtitle="Editar Grupo" />
            ) : (
              <HeaderForm title="Grupo" subtitle="Cadastrar" />
            )}
            <Card elevation={2} sx={{ borderRadius: '10px', padding: '5px' }}>
              <Typography p={2} variant="h6" gutterBottom>
                Informações Básicas
              </Typography>
              <Grid size={{ xs: 12, md: 6 }} pl={2} pr={2} pb={2}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Nome"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 12 }} p={2} textAlign="right">
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{ mr: 2 }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    mutationCreate.isPending || mutationUpdate.isPending
                  }
                >
                  {mutationCreate.isPending || mutationUpdate.isPending ? (
                    <CircularProgress size={24} />
                  ) : isEditing ? (
                    'Salvar Alterações'
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </AlphaBox>
  );
};

export default GroupFormPage;
