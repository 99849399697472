const storedTheme = localStorage.getItem('theme') ?? 'alphaLight';
const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: 'full',
    theme: storedTheme == 'alphaLight' ? 'alphaLight' : 'alphaDark',
  },
  topbar: {
    show: true,
    fixed: true,
    theme: storedTheme == 'alphaLight' ? 'alphaBlueDark' : 'alphaPurpleDark',
  },
};

export default Layout1Settings;
