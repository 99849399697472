import React from 'react';

import { Box, styled, useMediaQuery, useTheme, SxProps } from '@mui/material';

interface AlphaSidenavProps {
  sx?: SxProps;
  open: boolean;
  children: React.ReactNode;
  toggleSidenav: () => void;
  width?: string;
}

const SideNav = styled('div')(
  ({ theme, width }: { theme?: any; width: string }) => ({
    zIndex: 91,
    width: width,
    overflow: 'hidden',
    position: 'relative',
    transition: 'width 250ms ease',
    background: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 0,
      bottom: 0,
      position: 'absolute',
    },
  }),
);

const SideNavOverlay = styled('div')(() => ({
  zIndex: 90,
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0.74)',
}));

const AlphaSidenav = ({
  sx,
  open,
  children,
  toggleSidenav,
  width = '220px',
}: AlphaSidenavProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box height="100%" display="flex">
      <SideNav sx={sx} width={open || !isMobile ? width : '0px'}>
        {children}
      </SideNav>

      {open && isMobile && <SideNavOverlay onClick={toggleSidenav} />}
    </Box>
  );
};

export default AlphaSidenav;
