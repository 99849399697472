import { ReactNode } from 'react';
import { Card, Box, styled } from '@mui/material';

interface CardTitleProps {
  subtitle?: string;
}

const CardRoot = styled(Card)({
  height: '100%',
  padding: '20px 24px',
});

const CardTitle = styled('div')(({ subtitle }: CardTitleProps) => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
  marginBottom: subtitle ? '16px' : 0,
}));

interface SimpleCardProps extends CardTitleProps {
  children?: ReactNode;
  title?: ReactNode;
}

const SimpleCard = ({ children, title, subtitle }: SimpleCardProps) => {
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
