import React, { ErrorInfo, ReactNode } from 'react';
import {
  Modal,
  Typography,
  Button,
  Box,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Player } from '@lottiefiles/react-lottie-player';
import errorAnimation from '@core/assets/lotties/errorAnimation.json';

const AnimationContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '8rem',
  overflow: 'hidden',
}));

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

const environment = import.meta.env.VITE_APP_ENV;

class RouteErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Erro capturado:', error, errorInfo);
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <Modal open={true} onClose={this.handleReload}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 500,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
              textAlign: 'center',
              outline: 'none',
            }}
          >
            <AnimationContainer>
              <Player
                src={errorAnimation}
                keepLastFrame
                autoplay
                style={{ height: '8rem', width: '8rem', overflow: 'hidden' }}
              />
            </AnimationContainer>
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8 }}
              onClick={this.handleReload}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
              Ops! Algo deu errado.
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Parece que ocorreu um erro inesperado. Nossa equipe já foi
              notificada.
            </Typography>
            <Box
              sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 50, px: 4 }}
                onClick={() => (window.location.href = '/home')}
              >
                Ir para Página Inicial
              </Button>
            </Box>
          </Box>
        </Modal>
      );
    }

    return children;
  }
}

export default environment === 'production'
  ? RouteErrorBoundary
  : ({ children }: ErrorBoundaryProps) => <>{children}</>;
