import { createContext, useState, ReactNode } from 'react';
import { merge } from 'lodash';
import { AlphaLayoutSettings } from '@core/components/LayoutTheme/AlphaLayout/settings';

interface SettingsContextType {
  settings: typeof AlphaLayoutSettings | any;
  updateSettings: (update: Record<string, any>) => void;
}

export const SettingsContext = createContext<SettingsContextType>({
  settings: AlphaLayoutSettings,
  updateSettings: () => {},
});

interface SettingsProviderProps {
  settings?: typeof AlphaLayoutSettings;
  children: ReactNode;
}

export function SettingsProvider({
  settings,
  children,
}: SettingsProviderProps) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || AlphaLayoutSettings,
  );

  const handleUpdateSettings = (update: Record<string, any>) => {
    const merged = merge({}, currentSettings, update);
    setCurrentSettings(merged);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings: handleUpdateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
