import {
  Box,
  Button,
  Card,
  CardContent,
  styled,
  Typography,
} from '@mui/material';
import React, { ErrorInfo, ReactNode } from 'react';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const environment = import.meta.env.VITE_APP_ENV;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : '#e8e8e8',
  padding: 3,
}));

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Erro capturado:', error, errorInfo);
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <Container>
          <Card sx={{ maxWidth: 600, width: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <ErrorOutlineRoundedIcon
                  color="primary"
                  fontSize="large"
                  sx={{ mr: 1 }}
                />
                <Typography variant="h4" color="primary">
                  Ocorreu um erro
                </Typography>
              </Box>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph
                p={2}
                pt={0}
              >
                {environment === 'development'
                  ? error.toString()
                  : 'Parece que ocorreu um erro inesperado. Nossa equipe já foi notificada.'}
              </Typography>
              {errorInfo && (
                <Typography variant="body2" color="textSecondary" paragraph>
                  {errorInfo.componentStack}
                </Typography>
              )}
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => (window.location.href = '/home')}
                  sx={{ mt: 3 }}
                >
                  Ir para Página Inicial
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
