import { Box, Card, Typography, useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';

interface Props {
  title: string;
  Icon: any;
}

const IntegrationCardGraphic = ({ title, Icon }: Props) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        padding: 2,
        borderRadius: 4,
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.08)',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box
          bgcolor={
            Icon?.type.render.displayName === 'CheckIcon'
              ? theme.palette.success.main
              : theme.palette.error.main
          }
          borderRadius="50%"
          p={1.5}
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icon sx={{ fontSize: 35, color: 'white' }} />
        </Box>
        <Typography variant="subtitle1" color="textSecondary">
          {title}
        </Typography>
      </Box>

      {/* Espaço para o gráfico de erros */}
      <Box mt={2} width="100%">
        <ReactECharts
          option={{
            tooltip: { trigger: 'axis' },
            legend: {
              data: ['Erros', 'Integrações'],
              textStyle: {
                color: theme.palette.text.primary,
              },
            },
            xAxis: {
              type: 'category',
              data: ['Hoje'],
            },
            yAxis: { type: 'value' },
            series: [
              {
                name: 'Erros',
                type: 'bar',
                data: [1],
                itemStyle: {
                  color: theme.palette.error.main,
                },
                barWidth: '30%',
              },
              {
                name: 'Integrações',
                type: 'bar',
                data: [2],
                itemStyle: {
                  color: theme.palette.success.main,
                },
                barWidth: '30%',
              },
            ],
          }}
          style={{ height: 200 }}
        />
      </Box>
    </Card>
  );
};

export default IntegrationCardGraphic;
