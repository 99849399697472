import { request } from '@core/data/request';

export interface ModuleParams {
  id: number;
  name: string;
  icon: string;
}

export interface LabelParams {
  id: number;
  name: string;
  icon: string;
  functionality: Functionality[];
}

export interface Functionality {
  id: number;
  is_children?: number;
  module_id: number;
  name: string;
  icon: string;
  component: Component[];
  table_column: TableColumn[];
}

export interface Component {
  id: number;
  functionality_id: number;
  component_type_id: number;
  name: string;
  label: string;
  icon: string;
}

export interface TableColumn {
  id: number;
  table_id: number;
  name: string;
  label?: string;
  label_aux: any;
}

export interface editProps {
  id: number;
  name: string;
  icon?: string;
}

export interface editLabelParams {
  module: editProps[];
  functionality: editProps[];
  component: editProps[];
  table_column: editProps[];
}

export const fetchLabels = async (
  id: number | null,
): Promise<LabelParams[]> => {
  return request<LabelParams[]>({
    method: 'get',
    url: `/access-control/manage-label/${id}`,
  });
};

export const editLabels = async (data: editLabelParams) => {
  return request<editLabelParams>({
    method: 'put',
    url: `/access-control/manage-label`,
    data: {
      ...data,
    },
  });
};

export const fetchModules = async (): Promise<ModuleParams[]> => {
  return request<ModuleParams[]>({
    method: 'get',
    url: `/list-modules`,
  });
};
