import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CreateList, { TableRow } from '@core/components/DataTable/CreateList';
import { NavLink, useNavigate } from 'react-router-dom';
import { AlphaBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  CompanyListParams,
  fetchAllMatrices,
} from '../services/companyService';
import { useQuery } from '@tanstack/react-query';
import useTable from '@core/hooks/useTable';
import useComponent from '@core/hooks/useComponent';

const selectOptions = [
  { label: 'Sim', value: '1' },
  { label: 'Não', value: '0' },
];

const CompanyListPage: React.FC = () => {
  const navigate = useNavigate();
  const { columns, actions, updateColumns, updateActions, isLoadingStructure } =
    useTable({
      endpoint: 'CompanyList',
    });

  const { data: matrices } = useQuery({
    queryKey: ['matrices'],
    queryFn: fetchAllMatrices,
    select: (data) =>
      data.map((matriz) => ({
        value: matriz.id,
        label: matriz.name,
      })),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  const updateTableColumns = useCallback(() => {
    updateColumns([
      { column: 'id', header: '#', size: 100, hideFilter: true },
      {
        id: 'name@lm', // Name Filter with Like Middle
        column: 'name',
        header: 'Nome',
        accessorKey: 'name',
        filterVariant: 'text',
      },
      {
        id: 'nickname@lm', // Nickname Filter with Like Middle
        column: 'nickname',
        header: 'Apelido',
        accessorKey: 'nickname',
        filterVariant: 'text',
      },
      {
        id: 'telephone@lm', // Telephone Filter with Like Middle
        column: 'telephone',
        header: 'Telefone',
        accessorKey: 'telephone',
        filterVariant: 'text',
      },
      {
        column: 'matrix', // Select
        id: 'matrix_id',
        header: 'Matriz',
        accessorKey: 'matrix',
        filterVariant: 'select',
        selectOptions: matrices || [],
      },
      {
        id: 'address@lm', // Address Filter with Like Middle
        column: 'address',
        header: 'Endereço',
        accessorKey: 'address',
        filterVariant: 'text',
      },
      {
        id: 'city@lm', // City Filter with Like Middle
        column: 'city',
        header: 'Cidade',
        accessorKey: 'city',
        filterVariant: 'text',
      },
      {
        id: 'neighborhood@lm', // Neighborhood Filter with Like Middle
        column: 'neighborhood',
        header: 'Bairro',
        accessorKey: 'neighborhood',
        filterVariant: 'text',
      },
      {
        id: 'cep@lm', // CEP Filter with Like Middle
        column: 'cep',
        header: 'CEP',
        accessorKey: 'cep',
        filterVariant: 'text',
      },
      {
        id: 'pmc', // Select
        column: 'pmc',
        header: 'Pmc',
        filterVariant: 'select',
        selectOptions: [...selectOptions],
        Cell: ({ row }: { row: TableRow<CompanyListParams> }) => (
          <Typography>{row.original.pmc ? 'Sim' : 'Não'}</Typography>
        ),
      },
    ]);
  }, [matrices]);

  const updateTableActions = useCallback(() => {
    updateActions({
      editCompany: {
        action: (row: TableRow<CompanyListParams>) =>
          navigate(`/accessControl/manageCompany/edit/${row.original.id}`),
        getIcon: () => <EditRoundedIcon />,
      },
    });
  }, [navigate]);

  useEffect(() => {
    if (!isLoadingStructure) {
      updateTableColumns();
      updateTableActions();
    }
  }, [isLoadingStructure, updateColumns, updateActions]);

  const componentAddCompany = useComponent('/accessControl/manageCompany/add');

  return (
    <AlphaBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Gerenciar Empresas' },
        ]}
      />
      <Grid container spacing={3} alignItems="center" mb={2} mt={1}>
        <Grid size={{ xs: 12, sm: 12 }} style={{ textAlign: 'right' }}>
          {componentAddCompany.visible && (
            <NavLink to="/accessControl/manageCompany/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
              >
                {componentAddCompany.name}
              </Button>
            </NavLink>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Box mt={3}>
        {isLoadingStructure ? (
          <LinearProgress />
        ) : (
          <CreateList
            tableEndpoint="CompanyList"
            menuItems={actions}
            columns={columns}
            exportOptions={{ fileName: 'Empresas' }}
            propsQuery={{
              staleTime: 1000 * 60 * 3, // 3 minutes
            }}
          />
        )}
      </Box>
    </AlphaBox>
  );
};

export default CompanyListPage;
