import { createTheme, ThemeOptions } from '@mui/material';
import { forEach, merge } from 'lodash';
import { themeColors } from './themeColors';
import themeOptions from './themeOptions';

export interface ThemeColors {
  [key: string]: ThemeOptions;
}

function createAlphaThemes(): ThemeColors {
  let themes: ThemeColors = {};

  forEach(themeColors, (value, key) => {
    // Ensure that the merged theme options adhere to ThemeOptions
    themes[key] = createTheme(merge({}, themeOptions, value) as ThemeOptions);
  });

  return themes;
}

export const themes = createAlphaThemes();
