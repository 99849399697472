import { useEffect, useState } from 'react';
import { HeaderForm } from '@core/components/Form';
import { AlphaBox } from '@core/components/Layout';
import useAuthStore from '@core/store/authStore';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Box } from '@mui/material';

const Home = () => {
  const { user } = useAuthStore();
  const [finalFormattedDate, setFinalFormattedDate] = useState(
    'Falha ao obter horário..',
  );

  useEffect(() => {
    const currentDate = new Intl.DateTimeFormat('pt-BR', {
      weekday: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date());

    if (currentDate) {
      const dateParts = currentDate.split(' ');
      const weekday = dateParts[1].replace(',', '');
      const capitalizedWeekday =
        weekday.charAt(0).toUpperCase() + weekday.slice(1);
      const day = dateParts[2];
      const year = dateParts[0];

      const formattedDate = `${capitalizedWeekday} ${day}, ${year}`;
      setFinalFormattedDate(formattedDate);
    }
  }, []);

  return (
    <AlphaBox>
      <Box
        alignSelf={'center'}
        flexDirection={'row'}
        mb={1}
        style={{ display: 'flex', color: 'gray' }}
      >
        <AccessTimeRoundedIcon
          style={{ marginRight: '5px', fontSize: '20px' }}
        />
        {finalFormattedDate}
      </Box>
      <HeaderForm title={`Olá, ${user?.name}.`} subtitle="Bem vindo!" />
    </AlphaBox>
  );
};

export default Home;
