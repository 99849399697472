import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Button,
  Box,
  TextField,
  Typography,
  Divider,
  Card,
  Autocomplete,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import * as z from 'zod';
import { AlphaBox } from '@core/components/Layout';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { CustomMask } from '@core/components/Mask';
import {
  editCompany,
  fetchAllMatrices,
  fetchCompany,
  fetchFindCep,
  MatrixParams,
  registerCompany,
  RegisterCompanyParams,
} from '../services/companyService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { HeaderForm } from '@core/components/Form';
import { fetchAddressByCep } from '@core/services/viacepService';
import { useFormErrorHandler } from '@core/hooks/useFormErrorHandler';

const companySchema = z.object({
  name: z.string({ required_error: 'Nome é obrigatório' }),
  address: z.string({ required_error: 'Endereço é obrigatório' }),
  number: z.string({ required_error: 'Número é obrigatório' }),
  address_complement: z.string().optional(),
  telephone: z
    .string({ required_error: 'Telefone é obrigatório' })
    .min(14, 'Telefone deve ter 14 caracteres'),
  state_id: z.number({ required_error: 'Estado é obrigatório' }),
  city_id: z.number({ required_error: 'Cidade é obrigatória' }),
  neighborhood: z.string({ required_error: 'Bairro é obrigatório' }),
  matrix_id: z.number().min(1, 'Matriz é obrigatória'),
  nickname: z.string({ required_error: 'Apelido é obrigatório' }),
  logo: z.any().optional(),
  pmc: z.boolean(),
  cep: z
    .string({ required_error: 'CEP é obrigatório' })
    .min(8, 'CEP deve ter pelo menos 8 dígitos'),
});

const initialValues = {
  name: '',
  address: '',
  number: '',
  address_complement: '',
  telephone: '',
  state_id: 0,
  city_id: 0,
  neighborhood: '',
  matrix_id: 0,
  nickname: '',
  logo: null as File | null | undefined,
  pmc: false,
  cep: '',
};

const CompanyFormPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isEditing = !!id;

  const formik = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(companySchema),
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
        address: values.address + ', ' + values.number,
      };

      if (isEditing && id) {
        mutationUpdate.mutate({ id, values: updatedValues });
      } else {
        mutationCreate.mutate({ values: updatedValues });
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  });
  const { handleValidationError } = useFormErrorHandler(formik.setErrors);

  const { data: matrices } = useQuery<MatrixParams[]>({
    queryKey: ['matrices'],
    queryFn: fetchAllMatrices,
  });

  const mutationCreate = useMutation({
    mutationFn: ({ values }: { values: RegisterCompanyParams }) =>
      registerCompany(values),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      handleValidationError(error);
      console.error('Error create company:', error);
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: ({
      id,
      values,
    }: {
      id: string;
      values: RegisterCompanyParams;
    }) => editCompany(id, values),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error updating company:', error);
    },
  });

  const { data: company } = useQuery<RegisterCompanyParams>({
    queryKey: ['fetchCompany', id],
    queryFn: () => {
      if (id !== undefined) {
        return fetchCompany(id);
      }
      return Promise.reject('ID is undefined');
    },
    enabled: isEditing && id !== undefined,
  });

  useEffect(() => {
    if (company) {
      const addressParts = (company.address ?? '').split(', ');

      const address = addressParts.slice(0, -1).join(', ');
      const number =
        addressParts.length > 1 ? addressParts[addressParts.length - 1] : '';

      formik.setValues({
        ...company,
        state_id: company.city?.state_id ?? 0,
        pmc: company.pmc === 1 ? true : false,
        logo: company.logo ?? null,
        address: address,
        number: number,
      });
    }
  }, [company]);

  const useFetchCepFind = (cep: string) => {
    return useQuery({
      queryKey: ['cepFind', cep],
      queryFn: async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));

        return fetchFindCep(cep);
      },
      enabled: formik.values.cep.length === 9 && !isEditing,
      staleTime: 1000 * 60 * 10,
      retry: false,
    });
  };

  const { data: cepFind } = useFetchCepFind(formik.values.cep);

  useEffect(() => {
    if (cepFind) {
      formik.setFieldValue('city_id', cepFind?.city_id ?? '');
      formik.setFieldValue('state_id', cepFind?.state_id ?? '');
    }
  }, [cepFind]);

  const cep = formik.values.cep.replace(/\D/g, '');
  const useFetchAddressByCep = (cep: string) => {
    return useQuery({
      queryKey: ['address', cep],
      queryFn: async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));

        return fetchAddressByCep(cep);
      },
      enabled: cep.length === 8 && !isEditing,
      staleTime: 1000 * 60 * 10,
      retry: false,
    });
  };
  const { data: cepDetails, isFetching: isFetchingCep } =
    useFetchAddressByCep(cep);

  useEffect(() => {
    if (cepDetails) {
      formik.setFieldValue('address', cepDetails?.logradouro ?? '');
      formik.setFieldValue('neighborhood', cepDetails?.bairro ?? '');
    }
  }, [cepDetails]);

  return (
    <AlphaBox>
      <BreadcrumbForm
        routeSegments={[
          {
            name: 'Gerenciar Empresas',
            path: '/accessControl/manageCompany',
          },
        ]}
      />
      <Grid container spacing={3} alignItems="center" p={6}>
        <Grid size={{ xs: 12, md: 12 }}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {isEditing ? (
              <HeaderForm
                title={formik.values?.name}
                subtitle="Editar Empresa"
              />
            ) : (
              <HeaderForm title="Empresa" subtitle="Cadastrar" />
            )}
            <Card elevation={2} sx={{ borderRadius: '10px', padding: '5px' }}>
              <Typography p={2} variant="h6" gutterBottom>
                Informações Básicas
              </Typography>
              <Grid container spacing={2} pl={2} pr={2} pb={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    id="nickname"
                    name="nickname"
                    label="Apelido"
                    value={formik.values.nickname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nickname && Boolean(formik.errors.nickname)
                    }
                    helperText={
                      formik.touched.nickname && formik.errors.nickname
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 2 }}>
                  <TextField
                    fullWidth
                    id="telephone"
                    name="telephone"
                    label="Telefone"
                    value={formik.values.telephone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    slotProps={{
                      input: {
                        inputComponent: CustomMask as any,
                        inputProps: { mask: '(00) 00000-0000' },
                      },
                    }}
                    error={
                      formik.touched.telephone &&
                      Boolean(formik.errors.telephone)
                    }
                    helperText={
                      formik.touched.telephone && formik.errors.telephone
                    }
                  />
                </Grid>

                <Grid size={{ xs: 12, md: 4 }}>
                  <Autocomplete
                    fullWidth
                    id="matrix_id"
                    options={matrices || []}
                    getOptionLabel={(option: MatrixParams) => option.name}
                    value={
                      matrices?.find(
                        (matrix: MatrixParams) =>
                          matrix.id === formik.values.matrix_id,
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.matrix_id &&
                          Boolean(formik.errors.matrix_id)
                        }
                        helperText={
                          formik.touched.matrix_id && formik.errors.matrix_id
                        }
                        label="Matriz"
                      />
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('matrix_id', newValue?.id || '');
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 2 }}>
                  {/* <Grid container alignItems="center" justifyContent="center">
                    <Button
                      component="label"
                      fullWidth
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        fontSize: '1rem',
                      }}
                    >
                      Carregar Logo
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => {
                          formik.setFieldValue(
                            'logo',
                            event.currentTarget?.files
                              ? event.currentTarget.files[0]
                              : null,
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </Button>
                    {formik.touched.logo && formik.errors.logo && (
                      <Typography variant="caption" color="error">
                        {formik.errors.logo as string}
                      </Typography>
                    )}
                  </Grid> */}
                </Grid>
              </Grid>
              <Box p={2}>
                <Divider />
              </Box>
              <Typography p={2} variant="h6" gutterBottom>
                Dados de Localização
              </Typography>
              <Grid container spacing={2} pl={2} pr={2} pb={2}>
                <Grid size={{ xs: 12, md: 2 }}>
                  <TextField
                    fullWidth
                    id="cep"
                    name="cep"
                    label="CEP"
                    disabled={isFetchingCep}
                    value={formik.values.cep}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue('cep', e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {isFetchingCep && <CircularProgress size={24} />}
                          </InputAdornment>
                        ),
                        inputComponent: CustomMask as any,
                        inputProps: {
                          mask: '00000-000',
                        },
                      },
                    }}
                    error={formik.touched.cep && Boolean(formik.errors.cep)}
                    helperText={formik.touched.cep && formik.errors.cep}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Endereço"
                    disabled
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 1 }}>
                  <TextField
                    fullWidth
                    id="number"
                    name="number"
                    label="Número"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    helperText={formik.touched.number && formik.errors.number}
                  />
                </Grid>

                <Grid size={{ xs: 12, md: 4 }}>
                  <TextField
                    fullWidth
                    id="address_complement"
                    name="address_complement"
                    label="Complemento"
                    value={formik.values.address_complement}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address_complement &&
                      Boolean(formik.errors.address_complement)
                    }
                    helperText={
                      formik.touched.address_complement &&
                      formik.errors.address_complement
                    }
                  />
                </Grid>

                <Grid size={{ xs: 12, md: 4 }}>
                  <TextField
                    fullWidth
                    id="neighborhood"
                    name="neighborhood"
                    label="Bairro"
                    /* disabled */
                    value={formik.values.neighborhood}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.neighborhood &&
                      Boolean(formik.errors.neighborhood)
                    }
                    helperText={
                      formik.touched.neighborhood && formik.errors.neighborhood
                    }
                  />
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <Autocomplete
                    fullWidth
                    id="city_id"
                    disabled
                    options={
                      cepFind && cepFind?.city
                        ? [cepFind?.city]
                        : company
                          ? [company.city]
                          : []
                    }
                    getOptionLabel={(option) => option?.name || ''}
                    value={
                      (cepFind?.city?.id || company?.city?.id) ===
                      formik.values.city_id
                        ? cepFind?.city || company?.city
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.city_id &&
                          Boolean(formik.errors.city_id)
                        }
                        helperText={
                          formik.touched.city_id && formik.errors.city_id
                        }
                        label="Cidade"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const newCityId = newValue?.id || 0;
                      formik.setFieldValue('city_id', newCityId);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 1 }}>
                  <Autocomplete
                    fullWidth
                    id="state_id"
                    disabled
                    options={
                      cepFind && cepFind?.state
                        ? [cepFind?.state]
                        : company
                          ? [company.city?.state]
                          : []
                    }
                    getOptionLabel={(option: any) => option?.uf || ''}
                    value={
                      (cepFind?.state_id || company?.city?.state_id) ===
                      formik.values.state_id
                        ? cepFind?.state || company?.city?.state
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.state_id &&
                          Boolean(formik.errors.state_id)
                        }
                        helperText={
                          formik.touched.state_id && formik.errors.state_id
                        }
                        label="Estado"
                      />
                    )}
                    onChange={(event, newValue) => {
                      const newStateId = newValue?.id || 0;
                      formik.setFieldValue('state_id', newStateId);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Divider />
              </Grid>
              <Box p={2}>
                <Divider />
              </Box>
              <Typography p={2} variant="h6" gutterBottom>
                Informações Adicionais
              </Typography>
              <Grid container spacing={2} pl={2} pr={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    id="pmc"
                    name="pmc"
                    label="PMC"
                    select
                    value={formik.values.pmc ? 1 : 0}
                    onChange={(event) => {
                      const value = event.target.value === '1';
                      formik.setFieldValue('pmc', value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.pmc && Boolean(formik.errors.pmc)}
                    helperText={formik.touched.pmc && formik.errors.pmc}
                    slotProps={{
                      select: {
                        native: true,
                      },
                    }}
                  >
                    <option value="0" label="Não" />
                    <option value="1" label="Sim" />
                  </TextField>
                </Grid>
              </Grid>
              <Grid p={2} size={{ xs: 12, md: 12 }} textAlign="right">
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{ mr: 2 }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    mutationCreate.isPending || mutationUpdate.isPending
                  }
                >
                  {mutationCreate.isPending || mutationUpdate.isPending ? (
                    <CircularProgress size={24} />
                  ) : isEditing ? (
                    'Salvar Alterações'
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </AlphaBox>
  );
};

export default CompanyFormPage;
