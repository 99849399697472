import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Breadcrumb } from '@core/components/LayoutTheme';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonRemoveOutlined from '@mui/icons-material/PersonRemoveOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import CheckIcon from '@mui/icons-material/Check';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import IntegrationCard from '../components/IntegrationCard';
import IntegrationCardGraphic from '../components/IntegrationCardGraphic';

const IntegrationDashboardPage: React.FC = () => {
  const theme = useTheme();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onlineUsers = 2;
  const connectedMachines = 2;
  const offlineUsers = 20;

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (containerRef.current) {
        containerRef.current.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable full-screen mode: ${err.message}`,
          );
        });
      }
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!document.fullscreenElement);
  };

  return (
    <Box
      ref={containerRef}
      m={4}
      mt={3}
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
      }}
    >
      {!isFullScreen && (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Breadcrumb
            routeSegments={[{ name: 'Dashboard' }, { name: 'Integrações' }]}
          />
          <Tooltip title="Tela cheia">
            <IconButton onClick={toggleFullScreen} color="primary">
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Box mt={2} p={isFullScreen ? 2 : 0}>
        <Grid container spacing={2}>
          {/* Cards de integração */}
          <Grid size={{ xs: 12, sm: 4, md: 2 }}>
            <Box display={'flex'} gap={3} flexDirection={'column'}>
              <IntegrationCard
                value={onlineUsers}
                title="Usuários Online"
                color="primary"
                Icon={PeopleAltOutlinedIcon}
              />
              <IntegrationCard
                value={connectedMachines}
                title="Máquinas Conectadas"
                color="success"
                Icon={LanOutlinedIcon}
              />
              <IntegrationCard
                value={offlineUsers}
                title="Usuários Offline"
                color="error"
                Icon={PersonRemoveOutlined}
              />
            </Box>
          </Grid>

          {/* Saldo / Integrações */}
          <Grid size={{ xs: 12, sm: 4, md: 3.33 }}>
            <IntegrationCardGraphic title="Saldo" Icon={CheckIcon} />
          </Grid>

          <Grid size={{ xs: 12, sm: 4, md: 3.33 }}>
            <IntegrationCardGraphic
              title="Recebimento de Notas"
              Icon={CheckIcon}
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 4, md: 3.33 }}>
            <IntegrationCardGraphic title="Integração OF" Icon={CheckIcon} />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={3}>
          {/* Informações do Servidor */}
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <Card
              sx={{
                padding: 3,
                borderRadius: 4,
                boxShadow: '0 8px 32px rgba(0,0,0,0.15)',
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.divider}`,
                height: '330px',
                maxHeight: '330px',
              }}
            >
              <Typography variant="h5" mb={3} fontWeight="bold" color="primary">
                Informações do Servidor
              </Typography>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" fontWeight="medium">
                    CPU Utilizada:
                  </Typography>
                  <Typography variant="h6" color="error">
                    75%
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" fontWeight="medium">
                    Memória Usada:
                  </Typography>
                  <Typography variant="h6" color="warning.main">
                    8 GB / 16 GB
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" fontWeight="medium">
                    Espaço em Disco:
                  </Typography>
                  <Typography variant="h6" color="info.main">
                    500 GB / 1 TB
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" fontWeight="medium">
                    Temperatura do Servidor:
                  </Typography>
                  <Typography variant="h6" color="warning.main">
                    65°C
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" fontWeight="medium">
                    Status:
                  </Typography>
                  <Typography
                    variant="h6"
                    color="success.main"
                    fontWeight="bold"
                  >
                    Operacional
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* Log de Erros */}
          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
            <Card
              sx={{
                padding: 3,
                borderRadius: 4,
                boxShadow: '0 8px 32px rgba(0,0,0,0.15)',
                backgroundColor: theme.palette.background.default,
                border: `1px solid ${theme.palette.divider}`,
                height: '330px',
                maxHeight: '330px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5" mb={3} fontWeight="bold" color="primary">
                Log de Erros
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{
                  overflowY: 'auto', // Adiciona scroll vertical
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    color="error.main"
                  >
                    [09:45:23]
                  </Typography>
                  <Typography variant="body1">
                    Erro na conexão com o banco de dados
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    color="error.main"
                  >
                    [10:10:12]
                  </Typography>
                  <Typography variant="body1">
                    Falha na autenticação do usuário "suporte@ekan.com.br"
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    color="error.main"
                  >
                    [12:15:30]
                  </Typography>
                  <Typography variant="body1">
                    Erro ao carregar componente "Dashboard"
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default IntegrationDashboardPage;
