import { HeaderForm } from '@core/components/Form';
import { AlphaBox } from '@core/components/Layout';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import { Box, Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, styled } from '@mui/system';
import GroupManageColumns from '../components/GroupManageColumns';
import { useState } from 'react';
import {
  AllFunctionalitiesChildrenParams,
  AllFunctionalitiesParams,
  SaveGroupFunctionalityItemProps,
  savePermissionFunctionalityGroup,
} from '../services/groupService';
import GroupModuleAccordion from '../components/GroupModule';
import { useMutation } from '@tanstack/react-query';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { notify } from '@core/utils/notifyUtils';

const BoxModules = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: 10,
  borderTopLeftRadius: 18,
  borderTopRightRadius: 18,
  height: 'auto',
  overflow: 'auto',
  flexDirection: 'column',
}));

const BoxManage = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: 10,
  borderTopLeftRadius: 18,
  borderTopRightRadius: 18,
  height: 'auto',
  overflow: 'auto',
}));

const BoxComponents = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const StackModules = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(2),
  borderRadius: 10,
  height: 'auto',
  minHeight: '72vh',
}));

const GroupManagePage = () => {
  const location = useLocation();
  const group = location.state?.group;
  const [child, setChild] = useState<AllFunctionalitiesChildrenParams[]>([]);
  const [childEdit, setChildEdit] =
    useState<AllFunctionalitiesChildrenParams>();
  const [functionalities, setFunctionalities] = useState<
    AllFunctionalitiesParams[]
  >([]);
  const [modules, setModules] = useState();
  const navigate = useNavigate();

  const saveGroupMutation = useMutation({
    mutationFn: ({ id, values }: { id: string | number; values: any }) =>
      savePermissionFunctionalityGroup(id, values),
    onSuccess: () => {
      notify('info', 'Faça login novamente para ver as alterações');
      navigate(-1);
    },
    onError: (error) => {
      console.error('Erro ao salvar permissões do grupo:', error);
    },
  });

  const handleSave = () => {
    if (!group?.id) {
      console.error('ID do grupo não encontrado');
      return;
    }

    const functionalitiesEnabled = functionalities?.filter(
      (item) => item.visible === true,
    );

    const childsEnabled = child?.filter((item) => item.visible === true);

    const modulesObject = Object.entries(modules || {})
      .filter(([_, value]) => value === true)
      .map(([key]) => Number(key))
      .reduce(
        (acc, id, index) => {
          acc[index] = id;
          return acc;
        },
        {} as { [key: number]: number },
      );

    const payload: SaveGroupFunctionalityItemProps = {
      id: group.id,
      modules: modulesObject || [],
      functionalities: [...functionalitiesEnabled, ...childsEnabled],
    };

    saveGroupMutation.mutate({ id: group.id, values: payload });
  };

  return (
    <AlphaBox>
      <Box mb={1}>
        <BreadcrumbForm
          routeSegments={[
            { name: 'Gerenciar Grupos', path: '/accessControl/manageGroup' },
          ]}
        />
      </Box>
      <Grid container spacing={3} p={4}>
        <Grid size={{ xs: 12, sm: 12 }}>
          <HeaderForm title={group?.name || 'Grupo'} subtitle="Gerenciar" />
          <Divider sx={{ mt: -2 }} />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <StackModules>
            <BoxModules>
              <GroupModuleAccordion
                id={group?.id}
                child={child}
                setChild={setChild}
                functionalities={functionalities}
                setFunctionalities={setFunctionalities}
                setModules={setModules}
                setChildEdit={setChildEdit}
              />
              <Box
                p={2}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                height={'100%'}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSave}
                >
                  Salvar
                </Button>
              </Box>
            </BoxModules>
          </StackModules>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <StackModules>
            <BoxManage>
              {childEdit && childEdit.id ? (
                <GroupManageColumns
                  groupId={group.id}
                  childEdit={childEdit}
                  setChildEdit={setChildEdit}
                />
              ) : (
                <BoxComponents>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Typography
                      variant="h4"
                      fontWeight={700}
                      mb={2}
                      color="primary"
                    >
                      Gerenciar Grupo
                    </Typography>
                    <Divider flexItem sx={{ mb: 2 }} />
                    <Typography
                      variant="body1"
                      mb={3}
                      color="text.secondary"
                      maxWidth="600px"
                    >
                      Bem-vindo à seção de gerenciamento de grupo. Aqui você
                      pode modificar configurações, alternar visibilidade de
                      funcionalidades e personalizar as características do seu
                      grupo.
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                      <InfoRoundedIcon color="info" sx={{ mr: 1 }} />
                      <Typography variant="body2" color="info.main">
                        Selecione um componente no painel lateral para começar a
                        editar.
                      </Typography>
                    </Box>
                  </Box>
                </BoxComponents>
              )}
            </BoxManage>
          </StackModules>
        </Grid>
      </Grid>
    </AlphaBox>
  );
};

export default GroupManagePage;
