import { themes as initThemes } from '../AlphaTheme/initThemes';
import layout1Settings from './Layout1/Layout1Settings';

export interface Props {
  activeLayout: 'layout1' | 'layout2';
  activeTheme: string;
  perfectScrollbar: boolean;
  themes: typeof initThemes;
  layout1Settings: typeof layout1Settings;

  secondarySidebar: {
    show: boolean;
    open: boolean;
    theme: string;
  };

  footer: {
    show: boolean;
    fixed: boolean;
    theme: string;
  };
}

const storedTheme = localStorage.getItem('theme') ?? 'alphaLight';
const activeTheme =
  storedTheme == 'alphaLight' ? 'alphaBlueLight' : 'alphaBlueDark2';

export const AlphaLayoutSettings: Props = {
  activeLayout: 'layout1',
  activeTheme: activeTheme,
  perfectScrollbar: false,
  themes: initThemes,
  layout1Settings: layout1Settings,
  secondarySidebar: {
    show: false,
    open: false,
    theme: storedTheme,
  },
  footer: {
    show: true,
    fixed: false,
    theme: storedTheme,
  },
};
