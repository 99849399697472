import { Box, useTheme } from '@mui/material';

const SpanTableList = ({ label, row }: { label: string; row: any }) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{
        padding: '0.5rem 0.75rem',
        borderRadius: '1rem',
        backgroundColor: row.original.active
          ? theme.palette.success.light
          : theme.palette.error.light,
        color: row.original.active
          ? theme.palette.success.dark
          : theme.palette.error.dark,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        letterSpacing: '0.05em',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
      }}
    >
      {label}
    </Box>
  );
};

export default SpanTableList;
