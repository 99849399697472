// src/api/request.ts
import { AxiosRequestConfig } from 'axios';
import { api } from './api';
import { notify } from '@core/utils/notifyUtils';

interface RequestOptions<T> {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  url: string;
  data?: T;
  config?: AxiosRequestConfig;
}

export interface ApiResponse<T> {
  success: boolean;
  response: {
    rowCount: number;
    data: T[];
  };
}

export const request = async <T>({
  method,
  url,
  data,
  config,
}: RequestOptions<T>): Promise<T> => {
  try {
    const response = await api({
      method,
      url,
      ...(method.toLowerCase() === 'get' ? { params: data } : { data }),
      ...config,
    });
    return response.data.response as T;
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} request to ${url}:`, error);
    throw error;
  }
};

export const requestTable = async <T>({
  endpoint,
  params,
}: {
  endpoint: string;
  params: any;
}): Promise<ApiResponse<T>> => {
  try {
    const { offset, size, sorting, filters, globalFilter } = params;

    const queryParams = new URLSearchParams({
      offset: String(offset),
      limit: String(size),
      sorting: JSON.stringify(sorting),
      filters: JSON.stringify(filters),
      globalFilter: JSON.stringify(globalFilter),
    });

    const url = `${endpoint}?${queryParams.toString()}`;

    const response = await api.get<ApiResponse<T>>(url); // Aqui usamos o tipo ApiResponse<T>
    return response.data;
  } catch (error: any) {
    notify('errorTable', 'Erro ao carregar os dados');
    throw error;
  }
};
