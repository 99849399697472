import {
  Button,
  Divider,
  Stack,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  Switch,
} from '@mui/material';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ColumnVisibilityToggle = ({ headers }: { headers: any }) => (
  <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      p={2}
      sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}
    >
      <ViewColumnRoundedIcon />
      <Typography variant="h6">Visibilidade das Colunas</Typography>
    </Stack>
    <Divider />
    <Stack p={2} spacing={1} sx={{ maxHeight: '300px', overflowY: 'auto' }}>
      {headers.length > 0 ? (
        headers.map((header: any) => (
          <Stack
            key={header.id}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              backgroundColor: (theme) =>
                header.column.getIsVisible() ? 'action.hover' : 'transparent',
              borderRadius: 1,
              p: 1,
              transition: 'background-color 0.3s',
            }}
          >
            <Typography>{(header.column.columnDef as any).header}</Typography>
            <Switch
              checked={header.column.getIsVisible()}
              onChange={() => header.column.toggleVisibility()}
              color="primary"
            />
          </Stack>
        ))
      ) : (
        <Typography variant="body2" color="text.secondary" align="center">
          Não há registros disponíveis
        </Typography>
      )}
    </Stack>
    {headers.length > 0 && (
      <>
        <Divider />
        <Stack direction="row" justifyContent="space-between" p={2}>
          <Tooltip title="Ocultar Todas">
            <IconButton
              onClick={() => {
                headers.forEach((header: any) => {
                  header.column.getIsVisible() &&
                    header.column.toggleVisibility();
                });
              }}
            >
              <VisibilityOffIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            endIcon={<VisibilityIcon />}
            onClick={() => {
              headers.forEach((header: any) => {
                !header.column.getIsVisible() &&
                  header.column.toggleVisibility();
              });
            }}
          >
            Exibir Todas
          </Button>
        </Stack>
      </>
    )}
  </Paper>
);

export default ColumnVisibilityToggle;
