import { Box, Typography } from '@mui/material';

interface HeaderProps {
  title: string | undefined;
  subtitle?: string;
}

const HeaderForm = ({ title, subtitle }: HeaderProps) => {
  return (
    <Typography variant="h4" pb={2} gutterBottom>
      {title}
      <Box
        component="span"
        sx={{
          fontSize: '0.50em',
          color: 'gray',
          ml: 1,
          fontStyle: 'italic',
        }}
      >
        {subtitle}
      </Box>
    </Typography>
  );
};

export default HeaderForm;
