import { CloseRounded } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@mui/material';
import { motion } from 'framer-motion';

interface AlphaModalRootProps {
  children: React.ReactNode;
  title: string;
  onClose: () => void;
  open: boolean;
  styleProps?: any;
}

const MotionBox = motion.create(Box as any);

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
};

export function AlphaModalRoot({
  title,
  onClose,
  open,
  styleProps,
  children,
}: AlphaModalRootProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <MotionBox
        sx={{ ...style, ...styleProps }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0 }}
      >
        <Box
          px={2}
          py={1}
          alignItems={'center'}
          justifyContent={'space-between'}
          display={'flex'}
          sx={{
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
            backgroundColor: 'primary.main',
          }}
        >
          <Typography fontWeight={600} sx={{ fontSize: 15 }} color="white">
            {title}
          </Typography>
          <CloseRounded
            fontSize="small"
            sx={{ cursor: 'pointer', color: 'white' }}
            onClick={onClose}
          />
        </Box>

        {children}
      </MotionBox>
    </Dialog>
  );
}
