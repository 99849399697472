import { decrypt } from '@core/utils/cryptoUtils';
import { UserRoute } from '@core/data/roles';
import { useMemo } from 'react';

export const useUserRoutes = (): UserRoute[] => {
  const encryptedRoutes = localStorage.getItem('routes');

  const parsedRoutes = useMemo(() => {
    if (!encryptedRoutes) return [];

    const decryptedRoutes = decrypt(encryptedRoutes);
    return JSON.parse(decryptedRoutes);
  }, [encryptedRoutes]);

  return parsedRoutes;
};

export interface subRouteParams {
  isFather: string;
  name: string;
  path: string;
  id: number;
  functionalityId: number;
}

export const useUserRoutesFunctionalities = (): UserRoute[] => {
  const userRoutes = useUserRoutes();
  const functionalitiesRoutes = useMemo(() => {
    const allFuncs: UserRoute[] = [];
    userRoutes.forEach((route) => {
      route.functionality?.forEach(
        (subRoute: { children: any[]; module_id: any; name?: string }) => {
          subRoute.children?.forEach((func: subRouteParams) => {
            allFuncs.push({
              isFather: subRoute.name || '',
              path: func.path || '',
              name: func.name || '',
              id: func.id,
            });
          });
        },
      );
    });

    return allFuncs;
  }, [userRoutes]);

  return functionalitiesRoutes;
};

export const useUserRoutesComponents = (): UserRoute[] => {
  const userRoutes = useUserRoutes();
  const childrenRoutes = useMemo(() => {
    const allChildren: UserRoute[] = [];
    userRoutes.forEach((route) => {
      route.functionality?.forEach(
        (subRoute: { children: any[]; module_id: any }) => {
          subRoute.children?.forEach((components: { component: any[] }) => {
            components.component.forEach(
              (component: { route_app: { path: string }; name: string }) => {
                allChildren.push({
                  path: component.route_app?.path || '',
                  name: component.name || '',
                });
              },
            );
          });
        },
      );
    });

    return allChildren;
  }, [userRoutes]);

  return childrenRoutes;
};

export const useCreateRoutes = (
  routesArray: { name: string; path: string; element: React.ComponentType }[][],
) => {
  return useMemo(() => {
    return routesArray.flat().map((route) => ({
      ...route,
      path: `/${route.path}`,
    }));
  }, [routesArray]);
};
