import { Box, Divider, Stack } from '@mui/material';

interface AlphaModalFooterProps {
  children: React.ReactNode;
}

export function AlphaModalFooter({ children }: AlphaModalFooterProps) {
  return (
    <>
      <Divider sx={{ bgcolor: 'background.paper' }} />

      <Stack sx={{ flexShrink: 0, bgcolor: 'background.paper' }}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Divider sx={{ bgcolor: 'background.paper' }} />

          <Stack
            p={2}
            sx={{
              flexShrink: 0,
              bgcolor: 'background.paper',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {children}
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
