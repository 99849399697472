import { lazy } from 'react';

export type AlphaLayoutsType = {
  layout1?: React.ComponentType<any>;
  layout2?: React.ComponentType<any>;
};

export const AlphaLayouts: AlphaLayoutsType = {
  layout1: lazy(() => import('./Layout1/Layout1')),
};
