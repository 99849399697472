import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { AllFunctionalitiesParams } from '../services/groupService';
import { useEffect, useState } from 'react';
import FunctionalityDetails from './GroupFunctionalityDetails';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

interface FunctionalityAccordionProps {
  func: AllFunctionalitiesParams[];
  child: any;
  setChild: any;
  setChildEdit: any;
  functionalities: any;
  setFunctionalities: any;
}

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(1),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 280,
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: 'none',
  borderRadius: '12px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  margin: '16px 0',
  transition: 'all 0.3s ease',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.background.paper,
  '&:before': {
    display: 'none',
  },
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  '&.Mui-expanded': {
    margin: '16px 0',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiAccordionSummary-root': {
    padding: '0px 10px !important',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.grey[800]
          : theme.palette.grey[100],
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: '5px 5px 5px !important',
  },
  '& .MuiTypography-root': {
    fontWeight: 500,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
}));

const FunctionalityAccordion = ({
  func,
  child,
  setChild,
  setChildEdit,
  functionalities,
  setFunctionalities,
}: FunctionalityAccordionProps) => {
  const theme = useTheme();
  const [expandedCard, setExpandedCard] = useState<number | false>(false);
  const [functionalityList, setFunctionalityList] = useState(func);

  useEffect(() => {
    if (func && func.length > 0) {
      const initialVisibility = initializeVisibility();

      setVisibility(initialVisibility);
      initializedFuncState(initialVisibility);
    }
  }, [func]);

  const initializeVisibility = () => {
    let maxOrder = 0;

    func.forEach((item) => {
      if (item.order !== null) {
        maxOrder = Math.max(maxOrder, item.order);
      }
    });
    const initialVisibility: {
      [key: number]: {
        id: number;
        order: number;
        visible: boolean;
      };
    } = {};

    func.forEach((item, index) => {
      const existingItem = functionalities
        ? functionalities.find((f: any) => f.id === item.id)
        : null;

      let order = item.order;
      if (order === null || existingItem?.order === null) {
        maxOrder += 1;
        order = maxOrder;
      }
      initialVisibility[item.id] = {
        id: item.id,
        order: order,
        visible: item.visible,
      };
    });
    return initialVisibility;
  };

  const initializedFuncState = (initialVisibility: {
    [key: number]: {
      id: number;
      order: number;
      visible: boolean;
    };
  }) => {
    const updatedFuncState = func.map((item) => {
      const existingItem = functionalities?.find((f: any) => f.id === item.id);
      return {
        id: item.id,
        order:
          existingItem?.order || item.order || initialVisibility[item.id].order,
        visible: existingItem?.visible ?? initialVisibility[item.id].visible,
      };
    });

    setFunctionalities((prevFunc: any) => {
      const mergedFunc = [...(prevFunc || [])];
      updatedFuncState.forEach((newItem) => {
        const index = mergedFunc.findIndex((f: any) => f.id === newItem.id);
        if (index !== -1) {
          mergedFunc[index] = newItem;
        } else {
          mergedFunc.push(newItem);
        }
      });
      return mergedFunc;
    });
  };

  const [visibility, setVisibility] = useState<{
    [key: number]: {
      id: number;
      order: number;
      visible: boolean;
    };
  }>(initializeVisibility);

  const handleCheckboxChange = (id: number) => {
    setVisibility((prevVisibility: any) => {
      if (prevVisibility[id]) {
        expandedCard === id && setExpandedCard(false);
      }

      const newVisibility = {
        ...prevVisibility,
        [id]: {
          ...prevVisibility[id],
          visible: !prevVisibility[id].visible,
        },
      };

      setFunctionalities((prevFunc: any) => {
        return prevFunc.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              visible: newVisibility[id].visible,
            };
          }
          return item;
        });
      });

      return newVisibility;
    });
  };

  const handleExpandClick = (id: number) => {
    setExpandedCard(expandedCard === id ? false : id);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(functionalityList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFunctionalityList(items);

    setFunctionalities((prevFunc: any) => {
      const updatedFunc = prevFunc.map((existingItem: any) => {
        const newIndex = items.findIndex((item) => item.id === existingItem.id);
        return {
          ...existingItem,
          order: newIndex !== -1 ? newIndex + 1 : existingItem.order,
        };
      });
      return updatedFunc;
    });

    setVisibility((prevVisibility: any) => {
      const updatedVisibility = { ...prevVisibility };
      items.forEach((item, index) => {
        if (updatedVisibility[item.id]) {
          updatedVisibility[item.id] = {
            ...updatedVisibility[item.id],
            order: index + 1,
          };
        }
      });
      return updatedVisibility;
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="functionalities">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {functionalityList
              .sort(
                (a, b) =>
                  (visibility[a.id].order ?? 0) - (visibility[b.id].order ?? 0),
              )
              .map((item, index) => {
                const funcItem =
                  functionalities?.find((f: any) => f.id === item.id) ||
                  visibility[item.id];
                return (
                  <Draggable
                    key={item.name}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <StyledAccordion
                          key={item.name}
                          expanded={expandedCard === item.id}
                          style={{
                            transition: 'background-color 0.3s ease',
                          }}
                        >
                          <StyledAccordionSummary>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              width="100%"
                            >
                              <div
                                {...provided.dragHandleProps}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  width: '100%',
                                }}
                              >
                                <DragIndicatorRoundedIcon
                                  sx={{
                                    cursor: 'grab',
                                    color:
                                      theme.palette.mode === 'dark'
                                        ? theme.palette.grey[700]
                                        : theme.palette.grey[500],
                                  }}
                                />
                                <Checkbox
                                  icon={<VisibilityOffOutlinedIcon />}
                                  checkedIcon={<VisibilityOutlinedIcon />}
                                  checked={funcItem?.visible ?? false}
                                  onChange={() => handleCheckboxChange(item.id)}
                                  sx={{
                                    marginLeft: '-5px',
                                  }}
                                />
                                <Tooltip title={item.name} arrow>
                                  <CardTitle>{item.name}</CardTitle>
                                </Tooltip>
                              </div>
                            </Box>
                            <IconButton
                              onClick={() => handleExpandClick(item.id)}
                              sx={{
                                marginLeft: 'auto',
                                color: theme.palette.text.primary,
                              }}
                            >
                              <ExpandMoreOutlinedIcon
                                sx={{
                                  transform:
                                    expandedCard === item.id
                                      ? 'rotate(180deg)'
                                      : 'rotate(0deg)',
                                  transition: 'transform 0.3s ease',
                                }}
                              />
                            </IconButton>
                          </StyledAccordionSummary>
                          <AccordionDetails>
                            <FunctionalityDetails
                              children={item.children as any}
                              child={child}
                              setChild={setChild}
                              setChildEdit={setChildEdit}
                            />
                          </AccordionDetails>
                        </StyledAccordion>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FunctionalityAccordion;
