import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import {
  MRT_Row,
  MRT_RowData,
  MRT_TableContainer,
  MRT_TableOptions,
  MRT_TablePagination,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import { topBarHeight } from '@core/utils/constantUtils';
import { motion } from 'framer-motion';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { fetchTable } from '@core/services/tableService';
import useTableStore from '@core/store/tableStore';
import ColumnVisibilityToggle from './ColumnVisibilityToggle';
import { themeShadows } from '../LayoutTheme/AlphaTheme/themeColors';
import ExportModal from './ExportModal';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

export interface DataTableParams {
  offset: number;
  size: number;
  sorting: Object[];
  filters: Object[];
  globalFilter: string;
}

export type TableRow<T extends MRT_RowData> = {
  original: T;
} & MRT_Row<T>;

type Props = {
  columnsToDisplay?: Column[];
} & MRT_TableOptions<any>;

interface ApiResponse {
  data: Array<any>;
  rowCount?: number;
}

type DensityType = 'spacious' | 'comfortable' | 'compact';

export interface CreateListProps {
  tableEndpoint: string;
  propsQuery?: any;
  service?: (args: any) => Promise<any>;
  props?: Props;
  propsInitial?: Props;
  menuItems?: { [label: string]: MenuItemProps };
  columns: any;
  customFilters?: any;
  exportOptions?: ExportOptions;
  onRefresh?: () => void;
}

interface ExportOptions {
  fileName: string;
}

export interface MenuItemProps {
  route?: string;
  getLabel?: (row: any) => string;
  action?: (row: any) => void;
  getIcon?: (row: any) => JSX.Element;
  props?: any;
  menuItemProps?: any;
}

export interface Column {
  name: string;
  label: string;
  columns?: Column[];
}
// End of types

const RenderFilters = lazy(() => import('./RenderFilters'));

const DrawerFilter = styled('div')(() => ({
  padding: '16px',
  marginBottom: '1px',
  display: 'flex',
  alignItems: 'center',
  height: topBarHeight,
  boxShadow: themeShadows[6],
  '& h5': {
    marginLeft: '8px',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: '600',
    fontSize: '1rem',
  },
}));

const renderFiltersComponent = (
  type: 'drawer' | 'popover' | 'line',
  table: any,
  customFilters: any,
  setColumnFilters: any,
  handleFilterChange: any,
) => {
  if (type === 'line' || type === 'drawer' || type === 'popover') {
    return (
      <Suspense fallback={<CircularProgress />}>
        <RenderFilters
          table={table}
          customFilters={customFilters}
          setColumnFilters={setColumnFilters}
          headerFilter={true}
          filterType={type}
          handleFilterChange={handleFilterChange}
        />
      </Suspense>
    );
  }
  return null;
};

const densityIcons = {
  spacious: <DensityLargeIcon />,
  comfortable: <DensityMediumIcon />,
  compact: <DensitySmallIcon />,
};

// Component
const CreateList: React.FC<CreateListProps> = ({
  tableEndpoint,
  propsQuery = {},
  service,
  menuItems,
  props,
  propsInitial,
  columns,
  customFilters,
  exportOptions = { fileName: 'table' },
}) => {
  const [openExport, setOpenExport] = useState(false);
  const [limit, setLimit] = useState(400);
  const [savedPagination, setSavedPagination] = useState<number>();
  const [, setForceRender] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [, setFilterChangeCount] = useState(0);
  const [columnHeaders, setColumnHeaders] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const { init, preferences, pageSize, filterType, density } = useTableStore();
  const tableStore = useTableStore((state: any) => state);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: pageSize || 10,
  });
  const [panelOpen, setPanelOpen] = useState(false);
  const [anchorEls, setAnchorEls] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    open2: null,
    open3: null,
    open4: null,
  });
  const open2 = Boolean(anchorEls.open2);
  const open3 = Boolean(anchorEls.open3);
  const open4 = Boolean(anchorEls.open4);

  const handleClearFilters = () => {
    table.getLeafHeaders().forEach((header) => {
      if (header.id !== 'mrt-row-actions') {
        header.column.setFilterValue('');
      }
    });

    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));

    setSavedPagination(0);
  };

  const handleFilterChange = useCallback((value: any, isCustom = false) => {
    setFilterChangeCount((prev) => prev + 1);

    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  }, []);

  const handleOpen = (
    popover: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEls((prev) => ({ ...prev, [popover]: event.currentTarget }));
  };

  const handleClose = (popover: string) => {
    setAnchorEls((prev) => ({ ...prev, [popover]: null }));
  };

  const handleOpenExport = () => setOpenExport(true);
  const handleCloseExport = () => setOpenExport(false);

  const {
    data: { data = [], rowCount } = {},
    isError,
    isRefetching,
    isLoading,
    isSuccess: isDataSuccess,
  } = useQuery<ApiResponse>({
    queryKey: [
      '@table',
      tableEndpoint,
      pagination.pageSize,
      limit,
      sorting,
      columnFilters,
      globalFilter,
    ],

    queryFn: async () => {
      const params = {
        offset: pagination.pageIndex * pagination.pageSize,
        size: limit > pagination.pageSize ? limit : pagination.pageSize,
        filters: columnFilters ?? [],
        globalFilter: globalFilter ?? [],
        sorting: sorting ?? [],
      };
      //await new Promise((resolve) => setTimeout(resolve, 5000));

      const data = service
        ? service({ params })
        : await fetchTable(tableEndpoint, { params });
      const json = data.response as ApiResponse;
      return json;
    },
    placeholderData: keepPreviousData,
    keepPreviousData: true,
    ...propsQuery,
  });

  useEffect(() => {
    init({
      pageSize: pagination.pageSize,
      filterType: filterType,
      density: density,
    });
  }, [pagination.pageSize, filterType, density]);

  const handleAllData = () => {
    setLimit((prev) => Number.MAX_SAFE_INTEGER);
  };

  useEffect(() => {
    if (isDataSuccess) {
      setTimeout(() => {
        setPagination((prev) => ({
          ...prev,
          pageIndex: savedPagination || 0,
        }));
      }, 200);
    }
  }, [isDataSuccess, savedPagination]);

  useEffect(() => {
    if (tableStore.pageSize !== pagination.pageSize) {
      preferences({
        ...tableStore,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageSize]);

  useEffect(() => {
    table.setDensity(density);
  }, [density]);

  useEffect(() => {
    setForceRender((prev) => !prev);
  }, []);

  const handleDensityChange = (newDensity: DensityType) => {
    table.setDensity(newDensity);

    preferences({
      ...tableStore,
      density: newDensity,
    });
  };

  const filteredMenus = menuItems
    ? Object.entries(menuItems)
        .map(([key, value]) => ({
          label: key,
          ...value,
        }))
        .filter((menu) => menu.route)
    : [];

  const table = useMaterialReactTable({
    localization: MRT_Localization_PT_BR,
    columns,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableColumnOrdering: true,
    layoutMode: 'grid',
    columnFilterDisplayMode: 'custom',
    muiFilterTextFieldProps: ({ column }: any) => ({
      label: `Filtrar por ${column.columnDef.header}`,
    }),
    data,
    ...props,
    initialState: {
      ...propsInitial,
      showColumnFilters: false,
      columnSizing: {
        'mrt-row-actions': 70,
      },
      pagination: {
        pageIndex: 0,
        pageSize: pagination.pageSize,
      },
    },
    muiTableHeadCellProps() {
      return {
        sx: (theme) => ({
          fontWeight: 'bold',
          fontSize: '16px',
          color: theme.palette.text.primary,
          backgroundColor: (theme.palette.text as any).header,
        }),
      };
    },

    renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
      const keysToExclude = ['0', '1', '2', '10'];
      return internalColumnMenuItems.filter(
        (item) => !keysToExclude.includes((item as any).key),
      );
    },
    pageCount: Math.ceil((rowCount || 1) / pagination.pageSize),
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    paginationDisplayMode: 'pages',
    enableFilters: false,
    muiPaginationProps: {
      color: 'primary',
      rowsPerPageOptions: [5, 10, 20, 50, 100, 250, 400],
      shape: 'rounded',
      variant: 'text',
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Erro ao carregar os dados. Tente novamente.',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount ?? 0,
    muiSkeletonProps: {
      animation: 'wave',
    },
    muiLinearProgressProps: {
      color: 'primary',
    },
    muiCircularProgressProps: {
      color: 'primary',
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
      showAlertBanner: isError,
      isLoading: isLoading,
      showProgressBars: isRefetching,
      sorting,
    },
    enableRowVirtualization: true,
    muiTableBodyRowProps() {
      return {
        sx: (theme) => ({
          backgroundColor:
            theme.palette.mode == 'light' ? '#f5f5f5f' : '#131313',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        }),
      };
    },
    getRowId: (row) => row.id,
    positionActionsColumn: 'last',
    enableRowActions: false,
    ...(filteredMenus &&
      filteredMenus.length > 0 && {
        enableRowActions: true,
        renderRowActionMenuItems: ({ row, closeMenu }) => [
          filteredMenus.map((menu, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                menu.action && menu.action(row);
                closeMenu();
              }}
              {...(typeof menu.menuItemProps === 'function'
                ? menu.menuItemProps(row)
                : menu.menuItemProps)}
            >
              {menu.getIcon && menu.getIcon(row)}
              <Box
                component="span"
                sx={(theme) => ({
                  marginLeft: '5px',
                  ...(typeof menu.props === 'function'
                    ? menu.props(row, theme)
                    : menu.props),
                })}
              >
                {menu.getLabel && menu.getLabel(row)}
              </Box>
            </MenuItem>
          )),
        ],
      }),
  });

  const handleOpenColumnVisibility = (event: React.MouseEvent<HTMLElement>) => {
    setColumnHeaders(table.getCenterLeafHeaders());
  };

  const getNextDensity = (currentDensity: DensityType) => {
    switch (currentDensity) {
      case 'spacious':
        return 'comfortable';
      case 'comfortable':
        return 'compact';
      case 'compact':
      default:
        return 'spacious';
    }
  };

  return (
    <Card elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
      <Stack spacing={2}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.background.default
                : theme.palette.background.paper,
            pb: 2,
            pt: 2,
          }}
        >
          <Stack alignItems="center" display="flex" spacing={2}>
            {/* FILTRO LINHA */}
            <Box
              flexDirection={'row'}
              display={'flex'}
              flexWrap={'wrap'}
              width={'100%'}
              alignItems={'center'}
              pl={2}
            >
              {filterType === 'line' &&
                renderFiltersComponent(
                  'line',
                  table,
                  customFilters,
                  setColumnFilters,
                  handleFilterChange,
                )}
              {columnFilters.length > 0 && filterType === 'line' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleClearFilters()}
                  >
                    Limpar Filtros
                  </Button>
                </motion.div>
              )}
            </Box>
            {/* FIM FILTRO LINHA */}

            {/* EXIBIR/OCULTAR COLUNAS */}
            <Stack
              width="100%"
              direction="row"
              justifyContent="end"
              spacing={1}
              p={1}
              pb={2}
            >
              <Stack width="100%" direction="row" justifyContent="end">
                <Tooltip
                  title="Exibir/Ocultar Colunas"
                  color="default"
                  placement="top"
                >
                  <IconButton
                    onClick={(event) => {
                      handleOpen('open4', event);
                      handleOpenColumnVisibility(event);
                    }}
                    color="default"
                    sx={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.light,
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        color: 'white',
                      },
                    }}
                  >
                    <ViewColumnRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={open4}
                  anchorEl={anchorEls.open4}
                  onClose={() => handleClose('open4')}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <ColumnVisibilityToggle headers={columnHeaders} />
                </Popover>
              </Stack>
              {filterType === 'drawer' && (
                <Tooltip title="Filtros" color="default" placement="top">
                  <IconButton
                    onClick={(event) => setPanelOpen(!panelOpen)}
                    color="default"
                    sx={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.light,
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        color: 'white',
                      },
                    }}
                  >
                    <FilterListRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}

              {filterType === 'popover' && (
                <Tooltip title="Filtros" color="default" placement="top">
                  <IconButton
                    onClick={(event) => handleOpen('open3', event)}
                    color="default"
                    sx={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.light,
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        color: 'white',
                      },
                    }}
                  >
                    <FilterAltRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Popover
                open={open3}
                anchorEl={anchorEls.open3}
                onClose={() => handleClose('open3')}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={2}
                  pt={2}
                  pr={2}
                  pl={2}
                  pb={1}
                  width="300px"
                  maxWidth="300px"
                >
                  <FilterAltRoundedIcon />
                  <Typography variant="h6">Filtros</Typography>
                </Stack>
                <Divider />
                <Stack
                  pt={1}
                  pr={1}
                  pl={2}
                  justifyContent={'center'}
                  width="300px"
                  maxWidth="300px"
                >
                  {filterType === 'popover' &&
                    renderFiltersComponent(
                      'popover',
                      table,
                      customFilters,
                      setColumnFilters,
                      handleFilterChange,
                    )}
                </Stack>
                <Stack justifyContent="center" p={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleClearFilters()}
                  >
                    Limpar
                  </Button>
                </Stack>
              </Popover>
              {/* FIM FILTRO POPOVER */}

              {/* EXPORTAR */}
              {exportOptions && (
                <Tooltip title="Exportar" placement="top">
                  <IconButton
                    /* onClick={(event) => handleOpen('open2', event)} */
                    onClick={handleOpenExport}
                    color="default"
                    sx={{
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.light,
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        color: 'white',
                      },
                    }}
                  >
                    <FileDownloadRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Menu
                anchorEl={anchorEls.open2}
                open={open2}
                onClose={() => handleClose('open2')}
                slotProps={{
                  paper: {
                    elevation: 3,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.default',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              ></Menu>
              {/* FIM EXPORTAR */}
              <Tooltip title="Alterar Densidade da Tabela" placement="top">
                <IconButton
                  onClick={() => handleDensityChange(getNextDensity(density))}
                  color="default"
                  sx={{
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.primary.light,
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                      color: 'white',
                    },
                  }}
                >
                  {densityIcons[density]}
                </IconButton>
              </Tooltip>
            </Stack>
            {/* EXIBIR/OCULTAR COLUNAS */}
          </Stack>

          {/* TABELA */}
          <MRT_TableContainer
            table={table}
            sx={{
              '& .MuiPaper-root': {
                boxShadow: 'none',
                border: (theme) => `1px solid ${theme.palette.divider}`,
              },
            }}
          />

          <Box
            sx={{
              p: 2,
              pb: 0,
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.background.default
                  : theme.palette.background.paper,
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'stretch', sm: 'center' }}
              spacing={2}
            >
              <Typography variant="body2" color="text.secondary">
                Mostrando de {pagination.pageIndex * pagination.pageSize + 1}{' '}
                até{' '}
                {(pagination.pageIndex + 1) * pagination.pageSize <
                (rowCount || 0)
                  ? (pagination.pageIndex + 1) * pagination.pageSize
                  : rowCount}{' '}
                de{' '}
                {rowCount &&
                (rowCount < pagination.pageSize || limit > rowCount)
                  ? rowCount
                  : 'muitos'}{' '}
                registros
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <MRT_TablePagination table={table} />
                <Button
                  variant="outlined"
                  size="small"
                  disabled={
                    rowCount !== undefined &&
                    (rowCount < pagination.pageSize || limit > rowCount)
                  }
                  onClick={() => {
                    handleAllData();
                    setSavedPagination(pagination.pageIndex);
                    setPagination((prev) => ({
                      ...prev,
                      pageIndex: 0,
                    }));
                  }}
                >
                  {rowCount &&
                  (rowCount < pagination.pageSize || limit > rowCount) ? (
                    'Exibindo todos os registros'
                  ) : isLoading ? (
                    <CircularProgress size={24} />
                  ) : limit > (rowCount || 400) ? (
                    'Exibindo todos os registros'
                  ) : isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Exibir tudo'
                  )}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Drawer
        sx={{
          width: '100%',
          maxWidth: '400px',
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: '300px',
            boxShadow:
              '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
          },
        }}
        variant="temporary"
        anchor={'right'}
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        SlideProps={{
          timeout: 300,
          easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DrawerFilter
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.dark,
              },
            }}
          >
            <FilterListRoundedIcon />
            <Typography variant="h6" sx={{ marginLeft: '8px' }}>
              Filtros
            </Typography>
          </DrawerFilter>
          <Box p={2} sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {filterType === 'drawer' &&
              renderFiltersComponent(
                'drawer',
                table,
                customFilters,
                setColumnFilters,
                handleFilterChange,
              )}
          </Box>
          <Box p={2} sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleClearFilters()}
              sx={{
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              Limpar
            </Button>
          </Box>
        </Box>
      </Drawer>
      {openExport && (
        <ExportModal
          open={openExport}
          onClose={handleCloseExport}
          tableEndpoint={tableEndpoint}
          tableHeaders={columns}
          fileName={exportOptions.fileName}
        />
      )}
    </Card>
  );
};

export default CreateList;
