import { useAlphaModal } from '@core/components/Modal/AlphaModal';
import { Box, useTheme } from '@mui/material';
import JsonView from '@uiw/react-json-view';

import { lightTheme } from '@uiw/react-json-view/light';
import { darkTheme } from '@uiw/react-json-view/dark';

const LogModalBody = ({
  rowData,
  open,
  onClose,
}: {
  rowData: any;
  open: boolean;
  onClose: () => void;
}) => {
  const AlphaModal = useAlphaModal();
  const theme = useTheme();

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(rowData, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'log.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <AlphaModal.Root
      open={open}
      onClose={onClose}
      title="Log de Acesso"
      styleProps={{
        width: '80%',
        height: '90%',
        borderRadius: '16px',
        boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
      }}
    >
      <AlphaModal.Content>
        <Box
          sx={{
            maxHeight: 'calc(90vh - 100px)',
            overflowY: 'auto',
            padding: '5px',
          }}
        >
          <JsonView
            value={JSON.parse(rowData)}
            collapsed={2}
            enableClipboard={true}
            displayDataTypes={false}
            style={theme.palette.mode === 'dark' ? darkTheme : lightTheme}
          />
        </Box>
      </AlphaModal.Content>
      <AlphaModal.Footer>
        <AlphaModal.Action onClick={onClose} color="error" variant="text">
          Fechar
        </AlphaModal.Action>
        <AlphaModal.Action
          color="primary"
          variant="contained"
          sx={{ ml: 1 }}
          onClick={() => handleDownload}
        >
          Exportar
        </AlphaModal.Action>
      </AlphaModal.Footer>
    </AlphaModal.Root>
  );
};

export default LogModalBody;
