import React from 'react';

import { Box, styled, BoxProps } from '@mui/material';
import clsx from 'clsx';

interface StyledBoxProps extends BoxProps {
  ellipsis?: boolean;
}

interface Props {
  children?: React.ReactNode;
  className?: string;
  ellipsis?: boolean;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  sx?: any;
  color?: string;
  display?: string;
  pt?: number;
  fontSize?: number;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ ellipsis }) => ({
  ...(ellipsis && {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
}));

export const H1 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      mb={0}
      mt={0}
      fontSize="28px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const H2 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      mb={0}
      mt={0}
      fontSize="24px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const H3 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      mb={0}
      mt={0}
      fontSize="18px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const H4 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      mb={0}
      mt={0}
      fontSize="16px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const H5 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      mb={0}
      mt={0}
      fontSize="14px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const H6 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      mb={0}
      mt={0}
      fontSize="13px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Paragraph = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      mb={0}
      mt={0}
      fontSize="14px"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Small = ({
  children,
  className,
  ellipsis,
  textTransform,
  color,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      fontSize="12px"
      fontWeight="500"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Span = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Tiny = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: Props) => {
  return (
    <StyledBox
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      fontSize="10px"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
