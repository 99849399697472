import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, LinearProgress, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { NavLink, useNavigate } from 'react-router-dom';
import { AlphaBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { toggleUserStatus, UserParams } from '../services/userService';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAllGroups } from '../../ManageGroup/services/groupService';
import UserInfoModal from '../components/UserInfoModal';
import useSwal from '@core/hooks/useSwal';
import { lazy } from 'react';
import Loadable from '@core/components/LayoutTheme/Loadable';
import useTable from '@core/hooks/useTable';
import SpanTableList from '@core/components/Span/SpanTableList';
import useComponent from '@core/hooks/useComponent';
import { TableRow } from '@core/components/DataTable/CreateList';

const CreateList = Loadable(
  lazy(() => import('@core/components/DataTable/CreateList')),
);

const selectOptions = [
  { label: 'Ativo', value: '1' },
  { label: 'Inativo', value: '0' },
];

const UserListPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const showSwal = useSwal();
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [rowInfoModal, setRowInfoModal] = useState<UserParams>();
  const { columns, actions, updateColumns, updateActions, isLoadingStructure } =
    useTable({
      endpoint: 'UserList',
    });
  const { data: groups } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchAllGroups,
    select: (groups) =>
      groups.data.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    staleTime: 1000 * 60 * 3, // 3 minutes
  });

  const updateTableColumns = useCallback(() => {
    updateColumns([
      { column: 'id', header: '#', size: 100, hideFilter: true },
      {
        column: 'name',
        id: 'name@lm', // Name Filter with Like Middle
      },
      {
        column: 'email',
        id: 'email@lm', // Email Filter with Like Middle
        header: 'Email',
        enableClickToCopy: true,
      },
      {
        column: 'group_name',
        id: 'group_id',
        header: 'Grupo',
        filterVariant: 'select',
        selectOptions: groups || [],
      },
      {
        column: 'active',
        header: 'Status',
        filterVariant: 'select',
        selectOptions: [...selectOptions],
        Cell: ({ row }: { row: TableRow<UserParams> }) => (
          <SpanTableList
            label={row.original.active ? 'Ativo' : 'Inativo'}
            row={row}
          />
        ),
      },
    ]);
  }, [groups, theme]);

  const updateTableActions = useCallback(() => {
    updateActions({
      editUser: {
        action: (row: TableRow<UserParams>) =>
          navigate(`/accessControl/manageUser/edit/${row.original.id}`),
        getIcon: () => <EditRoundedIcon />,
      },
      status: {
        getLabel: (row: TableRow<UserParams>) =>
          `${row.original.active ? 'Inativar' : 'Ativar'}`,
        action: (row: TableRow<UserParams>) => {
          showSwal({
            title: `${row.original.active ? 'Inativar' : 'Ativar'} Usuário?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:
              theme.palette.mode === 'light'
                ? theme.palette.success.light
                : theme.palette.success.dark,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
              handleSituation(row.original);
            }
          });
        },
        getIcon: (row: TableRow<UserParams>) =>
          row.original.active ? <CloseRoundedIcon /> : <CheckRoundedIcon />,
      },
      info: {
        action: (row: TableRow<UserParams>) => {
          setOpenModal(true);
          setRowInfoModal(row.original);
        },
        getIcon: () => <AddRoundedIcon />,
      },
    });
  }, [navigate, theme]);

  useEffect(() => {
    if (!isLoadingStructure) {
      updateTableColumns();
      updateTableActions();
    }
  }, [isLoadingStructure, updateColumns, updateActions, theme]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const mutationSituation = useMutation({
    mutationFn: (row: any) => toggleUserStatus(row.id, !row.active),
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: (error: unknown) => {
      console.error('Error toggling user status:', error);
    },
  });

  const handleSituation = (row: any) => {
    mutationSituation.mutate(row);
  };

  const componentAddUser = useComponent('/accessControl/manageUser/add');

  return (
    <Fragment>
      <AlphaBox>
        <Breadcrumb
          routeSegments={[
            { name: 'Controle de Acesso' },
            { name: 'Gerenciar Usuários' },
          ]}
        />
        <Grid container spacing={3} alignItems="center" mb={2} mt={4}>
          <Grid size={{ xs: 12, md: 12 }} style={{ textAlign: 'right' }}>
            {componentAddUser.visible && (
              <NavLink to="/accessControl/manageUser/add">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddRoundedIcon />}
                >
                  {componentAddUser.name}
                </Button>
              </NavLink>
            )}
          </Grid>
        </Grid>
        <Divider />
        <Box mt={4}>
          {isLoadingStructure ? (
            <LinearProgress />
          ) : (
            <CreateList
              tableEndpoint="UserList"
              menuItems={actions}
              columns={columns}
              exportOptions={{ fileName: 'Usuários' }}
              propsQuery={{
                staleTime: 1000 * 60 * 3, // 3 minutes
              }}
            />
          )}
        </Box>
      </AlphaBox>
      <UserInfoModal
        row={rowInfoModal}
        open={openModal}
        onClose={handleCloseModal}
      />
    </Fragment>
  );
};

export default UserListPage;
