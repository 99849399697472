import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface Props {
  pageSize: number;
  filterType: string;
  density: 'spacious' | 'comfortable' | 'compact';
}

interface DataTableState extends Props {
  init: (payload: Props) => void;
  preferences: (payload: Props) => void;
}

const InitialPreferences: Props = {
  pageSize: 20,
  filterType: 'drawer',
  density: 'comfortable',
};

const environment = import.meta.env.VITE_APP_ENV;

const useTableStore = create<DataTableState>()(
  devtools(
    persist(
      (set) => ({
        ...InitialPreferences,
        init: (payload: Props) =>
          set(
            {
              ...payload,
            },
            false,
            'init',
          ),
        preferences: (payload: Props) =>
          set(
            {
              ...payload,
            },
            false,
            'preferences',
          ),
      }),
      {
        name: 'tablePreferences', // localStorage
      },
    ),
    {
      store: 'table', //devtools
      name: 'tablePreferences',
      enabled: environment == 'development', //devtools disable production
    },
  ),
);

export default useTableStore;
