import { api } from '@core/data/api';
import useAuthStore from '@core/store/authStore';
import { encrypt } from '@core/utils/cryptoUtils';

export const fetchUserRoutes = async (): Promise<any> => {
  const { logout } = useAuthStore.getState();

  try {
    const response = await api.get('/access-control/routes');
    const routesData = response.data.response;

    const encryptedRoutes = encrypt(JSON.stringify(routesData));

    localStorage.setItem('routes', encryptedRoutes || '');

    return routesData;
  } catch (error) {
    logout();
    throw error;
  }
};
