import { Breadcrumbs } from '@mui/material';

import { NavLink } from 'react-router-dom';
import {
  BreadcrumbProps,
  BreadcrumbRoot,
  StyledIcon,
  SubName,
} from './Breadcrumb';

export function BreadcrumbForm({ routeSegments }: BreadcrumbProps) {
  return (
    <BreadcrumbRoot>
      <Breadcrumbs
        separator={''}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          position: 'relative',
        }}
      >
        {routeSegments
          ? routeSegments.map((route, index) => {
              return (
                <NavLink to={route.path || '/'} key={index}>
                  <StyledIcon sx={{ paddingRight: '30px' }} color="primary">
                    arrow_back
                  </StyledIcon>
                  <SubName>{route.name}</SubName>
                </NavLink>
              );
            })
          : null}
      </Breadcrumbs>
    </BreadcrumbRoot>
  );
}

export default BreadcrumbForm;
