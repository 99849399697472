import { AlphaBox } from '@core/components/Layout';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';

const ProfileNotification = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <AlphaBox>
      <Typography variant={isMobile ? 'h5' : 'h4'} mb={1}>
        Notificações
      </Typography>
      <Typography color="gray" sx={{ mb: 5 }}>
        Defina as configurações de notificação
      </Typography>
      <Divider />
    </AlphaBox>
  );
};

export default ProfileNotification;
