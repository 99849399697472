import { Route, Routes } from 'react-router-dom';
import { AuthorizedPaths } from './path';
import RouteErrorBoundary from '@core/components/ErrorBoundary/RouteErrorBoundary';
import NotFound from './views/session/NotFound';

const IlogixModule = () => {
  const ilogixRoutes = AuthorizedPaths();

  return (
    <RouteErrorBoundary>
      <Routes>
        {ilogixRoutes.map((route: any, index: number) => (
          <Route key={index} path={route.path} element={<route.element />} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </RouteErrorBoundary>
  );
};

export default IlogixModule;
