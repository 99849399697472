import CustomCheckbox from '@core/components/CheckBox/CustomCheckBox';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';

const RenderComponents = ({
  components,
  checkedItems,
  setCheckedItems,
  theme,
  type,
  onDragEnd,
}: {
  components: any;
  checkedItems: Record<
    number,
    Record<number, { id: number; checked: boolean; order: number }>
  >;
  setCheckedItems: (
    items: (
      prev: Record<
        number,
        Record<number, { id: number; checked: boolean; order: number }>
      >,
    ) => Record<
      number,
      Record<number, { id: number; checked: boolean; order: number }>
    >,
  ) => void;
  theme: any;
  type: number;
  onDragEnd: (result: any, type: number) => void;
}) => {
  useEffect(() => {
    const initialCheckedItems: Record<
      number,
      { id: number; checked: boolean; order: number }
    > = {};

    const typeComponents =
      components &&
      components?.components.filter((c: any) => c.component_type_id === type);

    typeComponents &&
      typeComponents.forEach((c: any, index: number) => {
        initialCheckedItems[c.id] = {
          id: c.id,
          checked: c.visible === 1,
          order: c.order ?? 0,
        };
      });

    setCheckedItems((prev) => ({
      ...prev,
      [type]: { ...initialCheckedItems, ...prev[type] },
    }));
  }, [components, type, setCheckedItems]);

  const handleToggleCheck = (id: number) => {
    setCheckedItems((prev) => {
      const newState = { ...prev };
      const typeItems = { ...newState[type] };

      if (typeItems[id]) {
        if (typeItems[id].checked) {
          delete typeItems[id];

          const reorderedItems = Object.values(typeItems)
            .sort((a, b) => a.order - b.order)
            .map((item, index) => ({ ...item, order: index + 1 }));

          newState[type] = reorderedItems.reduce(
            (acc, item) => {
              acc[item.id] = item;
              return acc;
            },
            {} as Record<
              number,
              { id: number; checked: boolean; order: number }
            >,
          );

          return newState;
        } else {
          const maxOrder = Object.values(typeItems).reduce(
            (max, item) => Math.max(max, item.order),
            0,
          );

          typeItems[id] = { id: id, checked: true, order: maxOrder + 1 };
        }
      } else {
        const maxOrder = Object.values(typeItems).reduce(
          (max, item) => Math.max(max, item.order),
          0,
        );

        typeItems[id] = { id: id, checked: true, order: maxOrder + 1 };
      }

      return { ...newState, [type]: typeItems };
    });
  };

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result, type)}>
      {' '}
      <Droppable droppableId={`droppable-${type}`} direction="horizontal">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            display="flex"
            flexDirection="row"
            gap={2}
          >
            {components?.components
              .filter((c: any) => c.component_type_id === type)
              .sort((a: any, b: any) => {
                const isCheckedA = checkedItems[type]?.[a.id]?.checked ?? false;
                const isCheckedB = checkedItems[type]?.[b.id]?.checked ?? false;

                if (isCheckedA && isCheckedB) {
                  return (
                    (checkedItems[type]?.[a.id]?.order ?? 0) -
                    (checkedItems[type]?.[b.id]?.order ?? 0)
                  );
                } else if (isCheckedA) {
                  return -1;
                } else if (isCheckedB) {
                  return 1;
                }
                return 0;
              })
              .map((c: any, index: number) => {
                const isChecked = checkedItems[type]?.[c.id]?.checked ?? false;

                return (
                  <Draggable
                    key={c.id}
                    draggableId={`draggable-${c.id}`}
                    index={index}
                    isDragDisabled={!isChecked}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          mb: 1,
                          backgroundColor: isChecked
                            ? 'primary.main'
                            : theme.palette.background.paper,
                          p: 2,
                          border: '1px solid',
                          borderColor: isChecked
                            ? 'primary.main'
                            : theme.palette.mode === 'dark'
                              ? theme.palette.grey[800]
                              : theme.palette.grey[300],
                          height: 50,
                          borderRadius: 10,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          ...provided.draggableProps.style,
                          ...(isChecked && snapshot.isDragging
                            ? { background: theme.palette.primary.dark }
                            : {}),
                        }}
                        onClick={() => handleToggleCheck(c.id)}
                      >
                        <DragIndicatorRoundedIcon
                          fontSize="small"
                          sx={{
                            mr: 1,
                            color: isChecked
                              ? 'white'
                              : theme.palette.mode === 'dark'
                                ? 'white'
                                : 'black',
                          }}
                        />
                        <Typography
                          fontSize={14}
                          mr={1}
                          fontWeight="bold"
                          color={
                            isChecked
                              ? 'white'
                              : theme.palette.mode === 'dark'
                                ? 'white'
                                : 'black'
                          }
                        >
                          {c.name}
                        </Typography>
                        <CustomCheckbox
                          checked={isChecked}
                          onChange={() => handleToggleCheck(c.id)}
                        />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RenderComponents;
