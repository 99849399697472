import CryptoJS from 'crypto-js';

const key = import.meta.env.VITE_ENCRYPT_SECRET_KEY;

if (!key) {
  throw new Error('Secret key is not defined in environment variables');
}

export const hashMD5 = (data: any): string | null => {
  try {
    if (!data) return null;

    return CryptoJS.MD5(JSON.stringify(data)).toString();
  } catch (error: string | any) {
    throw new Error('Hashing failed');
  }
};

export const encrypt = (data: any): string | null => {
  try {
    if (!data) return null;

    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  } catch (error: string | any) {
    throw new Error('Encryption failed');
  }
};

export const decrypt = (ciphertext: string): any | null => {
  try {
    if (!ciphertext) return null;

    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error: string | any) {
    throw new Error('Encryption failed');
  }
};
