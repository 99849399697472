import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
}

interface DataRow {
  [key: string]: any;
}

interface HistoryTableProps {
  title?: string;
  columns: Column[];
  rows: DataRow[];
  headerColor?: 'success' | 'info' | 'danger' | 'warning' | string;
  tableHeight?: string | number;
}

const HistoryTable: React.FC<HistoryTableProps> = ({
  title = 'Histórico de Alterações',
  columns,
  rows,
  headerColor = 'success',
  tableHeight = 400,
}) => {
  const theme = useTheme();
  const { palette } = theme;

  const getColor = (color: string) => {
    switch (color) {
      case 'success':
        return palette.success.main;
      case 'info':
        return palette.info.main;
      case 'danger':
        return palette.error.main;
      case 'warning':
        return palette.warning.main;
      default:
        return color;
    }
  };

  const resolvedColor = getColor(headerColor);

  return (
    <Card elevation={3} sx={{ m: 2, overflow: 'hidden' }}>
      <Box sx={{ backgroundColor: resolvedColor, p: 2 }}>
        <Typography variant="h6" color="white">
          {title}
        </Typography>
      </Box>
      <TableContainer sx={{ maxHeight: tableHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  variant="head"
                  sx={{
                    minWidth: column.minWidth,
                    padding: '12px 16px',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    borderRight:
                      index < columns.length - 1
                        ? `1px solid ${palette.divider}`
                        : 'none',
                    backgroundColor: `background.${palette.mode === 'light' ? 'default' : 'paper'}`,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column, colIndex) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align || 'left'}
                      style={{
                        padding: '12px 16px',
                        borderRight:
                          colIndex < columns.length - 1
                            ? `1px solid ${palette.divider}`
                            : 'none',
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default HistoryTable;
