import { Button, Divider, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

import { useCallback, useEffect } from 'react';
import { AlphaBox } from '@core/components/Layout';
import { NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { lazy } from 'react';
import Loadable from '@core/components/LayoutTheme/Loadable';
import useTable from '@core/hooks/useTable';
import useComponent from '@core/hooks/useComponent';
import useAuthStore from '@core/store/authStore';
import { TableRow } from '@core/components/DataTable/CreateList';
import { GroupParams } from '../services/groupService';

const CreateList = Loadable(
  lazy(() => import('@core/components/DataTable/CreateList')),
);

const GroupListPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  const { columns, actions, updateActions, updateColumns, isLoadingStructure } =
    useTable({
      endpoint: 'GroupList',
    });

  const updateTableColumns = useCallback(() => {
    updateColumns([
      { column: 'id', header: '#', size: 100, hideFilter: true },
      {
        column: 'name',
        id: 'name@lm', // Name Filter with Like Middle
      },
    ]);
  }, []);

  const updateTableActions = useCallback(() => {
    updateActions({
      manage: {
        action: (row: TableRow<GroupParams>) =>
          navigate(`/accessControl/manageGroup/manage/${row.original.id}`, {
            state: { group: row.original },
          }),
        getIcon: () => <TuneRoundedIcon />,
        menuItemProps: (row: TableRow<GroupParams>) => ({
          disabled: row.original.id === 1 || user?.groupId != 1,
        }),
      },
      editGroup: {
        action: (row: TableRow<GroupParams>) =>
          navigate(`/accessControl/manageGroup/edit/${row.original.id}`),
        getIcon: () => <EditRoundedIcon />,
      },
    });
  }, [navigate]);

  useEffect(() => {
    if (!isLoadingStructure) {
      updateTableActions();
      updateTableColumns();
    }
  }, [isLoadingStructure, updateActions, updateTableColumns]);

  const componentAddGroup = useComponent('/accessControl/manageGroup/add');

  return (
    <AlphaBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Controle de Acesso' },
          { name: 'Gerenciar Grupos' },
        ]}
      />
      <Grid container spacing={3} alignItems="center" mb={2} mt={4}>
        <Grid size={{ xs: 12, sm: 12 }} style={{ textAlign: 'right' }}>
          {componentAddGroup.visible && (
            <NavLink to="/accessControl/manageGroup/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
              >
                {componentAddGroup.name}
              </Button>
            </NavLink>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid pt={4}>
        {isLoadingStructure ? (
          <LinearProgress />
        ) : (
          <CreateList
            tableEndpoint="GroupList"
            columns={columns}
            menuItems={actions}
            propsQuery={{
              staleTime: 1000 * 60 * 3, // 3 minutes
            }}
            exportOptions={{ fileName: 'Grupos' }}
          />
        )}
      </Grid>
    </AlphaBox>
  );
};

export default GroupListPage;
