import { HeaderForm } from '@core/components/Form';
import { AlphaBox } from '@core/components/Layout';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import HistoryTable from '@core/components/Table/HistoryTable';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const productSchema = z.object({
  company: z.number({ required_error: 'Empresa é obrigatório' }),
  code: z.string({ required_error: 'Código é obrigatório' }),
  description: z.string({ required_error: 'Descrição é obrigatório' }),
  unity_id: z.number({ required_error: 'Unidade é obrigatório' }),
});

const companies = [
  { id: 1, name: 'Company 1' },
  { id: 2, name: 'Company 2' },
];
const units = [
  { id: 1, name: 'Unity 1' },
  { id: 2, name: 'Unity 2' },
];
const criticalities = [
  { id: 1, name: 'Criticality 1' },
  { id: 2, name: 'Criticality 2' },
];
const historyColumns = [
  { id: 'data', label: 'Data' },
  { id: 'user', label: 'Usuário' },
];
const historyRows = [
  { data: '01/08/2024', user: 'João Santos' },
  { data: '23/07/2024', user: 'Igor Prado' },
  { data: '13/11/2024', user: 'Tiago Lang' },
  { data: '17/12/2024', user: 'Bruno Assunção' },
];

const ProductFormPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isEditing = !!id;

  const mutationCreate = useMutation({
    //mutationFn: registerGroup(values),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error create group:', error);
    },
  });
  const mutationUpdate = useMutation({
    /* mutationFn: ({ id, values }: { id: string; values: any }) =>
      editGroup(id, values), */
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error updating group:', error);
    },
  });

  const { data: product } = useQuery<any>({
    queryKey: ['fetchGroup', id],
    queryFn: () => {
      /*  if (id !== undefined) {
        return fetchGroup(id);
      } */
      return Promise.reject('ID is undefined');
    },
    enabled: isEditing && id !== undefined,
  });

  const formik = useFormik({
    initialValues: {
      company_id: 0,
      code: '',
      description: '',
      unity_id: 0,
      criticality_id: 0,
    },
    validationSchema: toFormikValidationSchema(productSchema),
    onSubmit: async (values) => {
      if (isEditing && id) {
        //mutationUpdate.mutate({ id, values });
      } else {
        //mutationCreate.mutate({ values });
      }
    },
  });

  useEffect(() => {
    if (product) {
      formik.setValues({
        company_id: product.name || 0,
        code: product.code || '',
        description: product.description || '',
        unity_id: product.unity_id || 0,
        criticality_id: product.criticality_id || 0,
      });
    }
  }, [product]);

  return (
    <AlphaBox>
      <BreadcrumbForm
        routeSegments={[
          {
            name: 'Gerenciar Produtos',
            path: '/tools/manageProduct',
          },
        ]}
      />
      <Grid container spacing={3} alignItems="center" p={6}>
        <Grid size={{ xs: 12, md: 12 }}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {isEditing ? (
              <HeaderForm
                //title={formik.values?.name}
                title="Editar Produto"
                subtitle="Editar Produto"
              />
            ) : (
              <HeaderForm title="Produto" subtitle="Cadastrar" />
            )}
            <Card elevation={2} sx={{ borderRadius: '10px', padding: '5px' }}>
              <Grid container spacing={2} p={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Autocomplete
                    fullWidth
                    id="company_id"
                    options={companies || []}
                    getOptionLabel={(option: any) => option.name}
                    value={
                      companies?.find(
                        (company: any) =>
                          company.id === formik.values.company_id,
                      ) || null
                    }
                    disabled={isEditing}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.company_id &&
                          Boolean(formik.errors.company_id)
                        }
                        helperText={
                          formik.touched.company_id && formik.errors.company_id
                        }
                        label="Empresa"
                      />
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('company_id', newValue?.id || '');
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    id="code"
                    name="code"
                    label="Código"
                    disabled={isEditing}
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} p={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Descrição"
                    disabled={isEditing}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Autocomplete
                    fullWidth
                    id="unity_id"
                    options={units || []}
                    disabled={isEditing}
                    getOptionLabel={(option: any) => option.name}
                    value={
                      units?.find(
                        (unity: any) => unity.id === formik.values.unity_id,
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          formik.touched.unity_id &&
                          Boolean(formik.errors.unity_id)
                        }
                        helperText={
                          formik.touched.unity_id && formik.errors.unity_id
                        }
                        label="Unidade"
                      />
                    )}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('unity_id', newValue?.id || '');
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                {isEditing ? (
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Autocomplete
                      fullWidth
                      id="criticality_id"
                      options={criticalities || []}
                      disabled={isEditing}
                      getOptionLabel={(option: any) => option.name}
                      value={
                        criticalities?.find(
                          (unity: any) =>
                            unity.id === formik.values.criticality_id,
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            formik.touched.criticality_id &&
                            Boolean(formik.errors.criticality_id)
                          }
                          helperText={
                            formik.touched.criticality_id &&
                            formik.errors.criticality_id
                          }
                          label="Unidade"
                        />
                      )}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          'criticality_id',
                          newValue?.id || '',
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                ) : null}
              </Grid>
              {!isEditing ? (
                <Grid container spacing={2} p={2}>
                  <HistoryTable
                    columns={historyColumns}
                    rows={historyRows}
                    headerColor="info"
                  />
                </Grid>
              ) : null}
              <Grid size={{ xs: 12, md: 12 }} p={2} textAlign="right">
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{ mr: 2 }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    mutationCreate.isPending || mutationUpdate.isPending
                  }
                >
                  {mutationCreate.isPending || mutationUpdate.isPending ? (
                    <CircularProgress size={24} />
                  ) : isEditing ? (
                    'Salvar Alterações'
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </AlphaBox>
  );
};

export default ProductFormPage;
