import Swal from 'sweetalert2';
import '@core/assets/styles/componentsStyles.css';
import { useTheme } from '@mui/material';

const useSwal = () => {
  const theme = useTheme();

  const swal = (options: any) => {
    return Swal.fire({
      background: theme.palette.background.default,
      animation: true,
      color: theme.palette.text.primary,
      customClass: {
        popup: 'custom-popup',
      },
      ...options,
    });
  };

  return swal;
};

export default useSwal;
