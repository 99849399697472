import { toast, ToastOptions, ToastContent, Id } from 'react-toastify';

const theme = localStorage.getItem('theme') === 'alphaDark' ? 'dark' : 'light';

const types = ['success', 'error', 'info', 'warning', 'errorTable'] as const;

export const notify = (
  type?: (typeof types)[number],
  message?: ToastContent,
  options?: ToastOptions,
) => {
  const toastId = message?.toString() as Id;

  const toastOptions: ToastOptions = {
    ...options,
    theme: theme,
    toastId: toastId,
  };

  if (toastId && !toast.isActive(toastId)) {
    switch (type) {
      case 'success':
        toast.success(message, toastOptions);
        break;
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'info':
        toast.info(message, toastOptions);
        break;
      case 'warning':
        toast.warning(message, toastOptions);
        break;
      case 'errorTable':
        toast.error(message, toastOptions);
        break;
      default:
        toast(message, toastOptions);
        break;
    }
  }
};
