import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';
import { CloseRounded } from '@mui/icons-material';
import {
  AllFunctionalitiesChildrenParams,
  listComponents,
  savePermissionComponentsGroup,
} from '../services/groupService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import RenderComponents from './GroupManageColumnsRComponents';
import { notify } from '@core/utils/notifyUtils';

interface ModalProps {
  groupId: number | string;
  childEdit: AllFunctionalitiesChildrenParams;
  setChildEdit: (childEdit: AllFunctionalitiesChildrenParams) => void;
}

const GroupManageColumns = ({
  groupId,
  childEdit,
  setChildEdit,
}: ModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [columns, setColumns] = useState<
    {
      id: number;
      name: string;
      visible: number;
      order: number;
    }[]
  >([]);
  const [checkedItems, setCheckedItems] = useState<
    Record<
      number,
      Record<number, { id: number; checked: boolean; order: number }>
    >
  >({});

  const { data: components, isLoading } = useQuery({
    queryKey: ['list-components', childEdit.id],
    queryFn: () => listComponents(2, childEdit.id),
    enabled: !!childEdit.id,
  });

  useEffect(() => {
    if (!isLoading && components?.columns) {
      const sortedColumns = components.columns.sort((a: any, b: any) => {
        if (a.visible === b.visible) {
          return a.order - b.order;
        }
        return b.visible - a.visible;
      });

      const updatedColumns = sortedColumns.map((column, index) => ({
        ...column,
        order: column.visible === 1 ? index + 1 : 0,
      }));

      setColumns(updatedColumns as any);
    }
  }, [components, isLoading]);

  const saveGroupMutation = useMutation({
    mutationFn: ({
      id,
      functionalityId,
      values,
    }: {
      id: string | number;
      functionalityId: string | number;
      values: any;
    }) => savePermissionComponentsGroup(id, functionalityId, values),
    onSuccess: () => {
      notify('info', 'Faça login novamente para ver as alterações');
      navigate(-1);
    },
    onError: (error) => {
      console.error('Erro ao salvar permissões do grupo:', error);
    },
  });

  const getActiveComponentsByType = (type: number) => {
    return Object.entries(checkedItems[type] || {})
      .filter(([, item]) => item.checked)
      .sort((a, b) => a[1].order - b[1].order)
      .map(([id, item]) => ({ id: Number(id), order: item.order }));
  };

  const handleSubmit = () => {
    const activeComponentsType1 = getActiveComponentsByType(1);
    const activeComponentsType2 = getActiveComponentsByType(2);

    const visibleColumns = columns
      .filter((column) => column.visible === 1)
      .sort((a, b) => a.order - b.order)
      .map((column) => ({ id: column.id, order: column.order }));

    const activeComponents = [
      ...activeComponentsType1,
      ...activeComponentsType2,
    ];

    const payload = {
      components: activeComponents,
      columns: visibleColumns,
    };

    saveGroupMutation.mutate({
      id: groupId,
      functionalityId: childEdit.id,
      values: payload,
    });
  };

  const handleToogleColumns = ({ visible }: { visible: number }) => {
    setColumns((prev) => {
      const updatedColumns = prev.map((column) => ({ ...column, visible }));

      if (visible === 1) {
        return updatedColumns
          .map((column, index) => ({ ...column, order: index + 1 }))
          .sort((a, b) => a.order - b.order);
      } else {
        return updatedColumns.map((column) => ({ ...column, order: 0 }));
      }
    });
  };

  const handleToogleColumn = ({
    visible,
    id,
  }: {
    visible: number;
    id: number;
  }) => {
    setColumns((prev) => {
      const updatedColumns = prev.map((column) => {
        if (column.id === id) {
          return { ...column, visible };
        }
        return column;
      });

      // Reordenar e atualizar ordens
      const inUseColumns = updatedColumns
        .filter((col) => col.visible === 1)
        .sort((a, b) => a.order - b.order)
        .map((col, index) => ({ ...col, order: index + 1 }));

      const availableColumns = updatedColumns
        .filter((col) => col.visible === 0)
        .map((col) => ({ ...col, order: 0 }));

      return [...inUseColumns, ...availableColumns];
    });
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    setColumns((prev) => {
      const updatedColumns = Array.from(prev);
      const columnId = parseInt(draggableId, 10);
      const columnIndex = updatedColumns.findIndex(
        (col) => col.id === columnId,
      );

      if (columnIndex === -1) return prev;

      const [reorderedItem] = updatedColumns.splice(columnIndex, 1);

      if (source.droppableId !== destination.droppableId) {
        reorderedItem.visible = destination.droppableId === '1' ? 1 : 0;
      }

      const destinationColumns = updatedColumns.filter(
        (col) => col.visible === (destination.droppableId === '1' ? 1 : 0),
      );
      const insertIndex = Math.min(
        destination.index,
        destinationColumns.length,
      );
      const globalInsertIndex =
        updatedColumns.findIndex(
          (col) => col.visible === (destination.droppableId === '1' ? 1 : 0),
        ) + insertIndex;

      updatedColumns.splice(globalInsertIndex, 0, reorderedItem);

      const inUseColumns = updatedColumns
        .filter((col) => col.visible === 1)
        .map((col, index) => ({ ...col, order: index + 1 }));

      const availableColumns = updatedColumns
        .filter((col) => col.visible === 0)
        .map((col) => ({ ...col, order: 0 }));

      return [...inUseColumns, ...availableColumns];
    });
  };

  const onDragEndComponentsType = (result: any, type: number) => {
    if (!components || !components.components) return;
    const { source, destination } = result;

    if (!destination) return;

    if (source.index === destination.index) {
      return;
    }

    setCheckedItems((prev) => {
      const updatedItems = { ...prev };
      if (!updatedItems[type]) updatedItems[type] = {};

      const orderedItems = Object.values(updatedItems[type])
        .filter((item) => item.checked)
        .sort((a, b) => a.order - b.order);

      const [reorderedItem] = orderedItems.splice(source.index, 1);

      orderedItems.splice(destination.index, 0, reorderedItem);

      orderedItems.forEach((item, index) => {
        updatedItems[type][item.id].order = index + 1;
      });

      return updatedItems;
    });
  };

  useEffect(() => {
    setCheckedItems({});
  }, [childEdit]);

  return (
    <Box width={'100%'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4" fontWeight="bold">
          {childEdit.name || 'Componentes'}
        </Typography>
        <CloseRounded
          sx={{ cursor: 'pointer', color: theme.palette.text.secondary }}
          onClick={() => setChildEdit({} as AllFunctionalitiesChildrenParams)}
        />
      </Box>
      <Grid size={{ xs: 12, md: 12 }} p={3} pt={2} pb={0}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          mb={2}
        >
          <Typography variant="h6" fontWeight="bold" mr={1}>
            Componentes
          </Typography>
          <Tooltip title="Dica: Clique e arraste para reordenar">
            <InfoRoundedIcon color="primary" sx={{ mr: 1 }} />
          </Tooltip>
        </Box>

        <Box gap={2} flexWrap={'wrap'} display={'flex'}>
          <RenderComponents
            components={components}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            theme={theme}
            type={1}
            onDragEnd={(result) => onDragEndComponentsType(result, 1)}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          mb={2}
        >
          <Typography variant="h6" fontWeight="bold" mr={1}>
            Ações
          </Typography>
          <Tooltip title="Dica: Clique e arraste para reordenar">
            <InfoRoundedIcon color="primary" sx={{ mr: 1 }} />
          </Tooltip>
        </Box>
        <Box gap={2} flexWrap={'wrap'} display={'flex'}>
          <RenderComponents
            components={components}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            theme={theme}
            type={2}
            onDragEnd={(result) => onDragEndComponentsType(result, 2)}
          />
        </Box>
        <Divider sx={{ my: 2, mb: 3 }} />
      </Grid>
      <Grid size={{ xs: 12, md: 12 }} p={3} pt={2} pb={0}>
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Colunas
        </Typography>
        <Grid p={2} pr={0} pt={2} size={{ xs: 12, md: 12 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
              }}
            >
              <Grid
                size={{ xs: 6, sm: 6 }}
                sx={{
                  boxShadow: `0px 0px 1px 1px ${theme.palette.divider}`,
                  borderRadius: '0.25rem',
                }}
                pr={2}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pb={1}
                >
                  <Box display="flex" alignItems="center" ml={2} mt={1}>
                    <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Disponível
                    </Typography>
                  </Box>
                  <Tooltip placement="top" title="Adicionar todas as colunas">
                    <AddCircleRoundedIcon
                      fontSize="small"
                      color="disabled"
                      sx={{
                        cursor: 'pointer',
                        ':hover': { color: 'primary.main' },
                      }}
                      onClick={() => handleToogleColumns({ visible: 1 })}
                    />
                  </Tooltip>
                </Box>
                <Droppable droppableId="0">
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{
                        minHeight: 200,
                        borderColor: 'grey.100',
                        borderRadius: 1,
                        p: 1,
                      }}
                    >
                      {columns
                        .filter((c) => c.visible === 0)
                        .map((column, index) => (
                          <Draggable
                            key={column.id}
                            draggableId={column.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Paper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                elevation={snapshot.isDragging ? 6 : 1}
                                sx={{
                                  p: 1,
                                  mb: 1,
                                  ml: 1,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  bgcolor: snapshot.isDragging
                                    ? theme.palette.primary.main
                                    : 'background.paper',
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <DragIndicatorRoundedIcon
                                    fontSize="small"
                                    sx={{
                                      mr: 1,
                                      color: `${snapshot.isDragging ? 'white' : 'text.secondary'}`,
                                    }}
                                  />
                                  <Typography
                                    color={
                                      snapshot.isDragging
                                        ? 'white'
                                        : 'text.primary'
                                    }
                                  >
                                    {column.name}
                                  </Typography>
                                </Box>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleToogleColumn({
                                      visible: 1,
                                      id: column.id,
                                    })
                                  }
                                  sx={{
                                    color: `${snapshot.isDragging ? 'white' : 'text.secondary'}`,
                                  }}
                                >
                                  <AddCircleOutlineRoundedIcon fontSize="small" />
                                </IconButton>
                              </Paper>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Grid>
              <Grid
                size={{ xs: 6, sm: 6 }}
                sx={{
                  boxShadow: `0px 0px 1px 1px ${theme.palette.divider}`,
                  borderRadius: '0.25rem',
                  marginLeft: '0.5rem',
                }}
                pr={2}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pb={1}
                >
                  <Box display="flex" alignItems="center" ml={2} mt={1}>
                    <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                      Em Uso
                    </Typography>
                  </Box>
                  <Tooltip placement="top" title="Remover todas as colunas">
                    <DoDisturbOnRoundedIcon
                      fontSize="small"
                      color="disabled"
                      sx={{
                        cursor: 'pointer',
                        ':hover': { color: 'primary.main' },
                      }}
                      onClick={() => handleToogleColumns({ visible: 0 })}
                    />
                  </Tooltip>
                </Box>
                <Droppable droppableId="1">
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{
                        minHeight: 200,
                        borderRadius: 1,
                        p: 1,
                      }}
                    >
                      {columns
                        .filter((c) => c.visible === 1)
                        .map((column, index) => (
                          <Draggable
                            key={column.id}
                            draggableId={column.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Paper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                elevation={snapshot.isDragging ? 6 : 1}
                                sx={{
                                  p: 1,
                                  mb: 1,
                                  ml: 1,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  bgcolor: snapshot.isDragging
                                    ? 'primary.main'
                                    : 'background.paper',
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <DragIndicatorRoundedIcon
                                    fontSize="small"
                                    sx={{
                                      mr: 1,
                                      color: `${snapshot.isDragging ? 'white' : 'text.secondary'}`,
                                    }}
                                  />
                                  <Typography
                                    color={
                                      snapshot.isDragging
                                        ? 'white'
                                        : 'text.primary'
                                    }
                                  >
                                    {column.name}
                                  </Typography>
                                </Box>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleToogleColumn({
                                      visible: 0,
                                      id: column.id,
                                    })
                                  }
                                  sx={{
                                    color: `${snapshot.isDragging ? 'white' : 'text.secondary'}`,
                                  }}
                                >
                                  <DoNotDisturbOnOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Paper>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Grid>
            </Grid>
          </DragDropContext>
        </Grid>
        <Box display="flex" justifyContent="flex-end" p={2} pr={0}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default GroupManageColumns;
